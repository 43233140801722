import './App.css'
import './styling/fonts.css'
import './assets/fonts/MonotypeOldEnglishTextW01SH.ttf'
import './assets/fonts/MonotypeOldEnglishTextW01SH.woff'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Route, Routes } from 'react-router-dom'
import React, { Suspense, Component } from 'react'
///
import Footer from './components/general/generalFooter'
import LoaderCP from './components/general/generalLoader'
import ContainedNavBar from './components/general/generalNavHeader'
import { ButtonToTop } from './components/general/generalButtonToTop'
///
import { getFooterSize } from './helpers/displayHandler.ts'
///
import CookieConsentComponent from './components/cookieConsent/CookieConsentComponent'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
///
import PathToRoute from './hooks/pathToRoute'
import NavigateTo from './hooks/navigateTo'
import { Wrapper } from './hooks/wrapper'
import { TabSEO } from './hooks/tabSEO'
///
import { Helmet } from 'react-helmet'

const HomePage = React.lazy(() =>
    import('./pages/home/home.jsx').then((module) => ({
        default: module.HomePage,
    }))
)
const Floors = React.lazy(() =>
    import(/* webpackPrefetch: true */ './pages/floors/floors.jsx').then((module) => ({
        default: module.Floors,
    }))
)
const TerracedPage = React.lazy(() =>
    import(/* webpackPrefetch: true */ './pages/flatTypes/terraced/terraced.jsx').then((module) => ({
        default: module.TerracedPage,
    }))
)
const MainHousePage = React.lazy(() =>
    import(/* webpackPrefetch: true */ './pages/flatTypes/mainhouse/main.jsx').then((module) => ({
        default: module.MainHousePage,
    }))
)
const ArchwayPage = React.lazy(() =>
    import('./pages/flatTypes/archway/archway.jsx').then((module) => ({
        default: module.ArchwayPage,
    }))
)
const ContactPage = React.lazy(() =>
    import('./pages/contact/contact.jsx').then((module) => ({
        default: module.ContactPage,
    }))
)
const FlatPage = React.lazy(() =>
    import('./pages/flat/flat.jsx').then((module) => ({
        default: module.FlatPage,
    }))
)
const LocationPage = React.lazy(() =>
    import('./pages/location/location.jsx').then((module) => ({
        default: module.LocationPage,
    }))
)
const OtherProjectsPage = React.lazy(() =>
    import('./pages/otherProjects/otherProjects.jsx').then((module) => ({
        default: module.OtherProjectsPage,
    }))
)
const ConstructionPage = React.lazy(() =>
    import('./pages/construction/construction.jsx').then((module) => ({
        default: module.ConstructionPage,
    }))
)
const HistoryPage = React.lazy(() =>
    import('./pages/history/history.jsx').then((module) => ({
        default: module.HistoryPage,
    }))
)
const ImpressumPage = React.lazy(() =>
    import('./pages/misc/impressum/impressum.jsx').then((module) => ({
        default: module.ImpressumPage,
    }))
)
const DataSecurityPage = React.lazy(() =>
    import('./pages/misc/dataSecurity/dataSecurity.jsx').then((module) => ({
        default: module.DataSecurityPage,
    }))
)
const ErrorCP = React.lazy(() =>
    import('./components/general/generalError.jsx').then((module) => ({
        default: module.ErrorCP,
    }))
)

export class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menuBarOpen: false,
            currentRoute: '/',
            isBackButtonClicked: false,
            homeVisited: false,
            footerHeight: undefined,
        }
    }
    onBackButtonEvent = () => {
        //e.preventDefault();
        this.setState(() => ({ currentRoute: window.location.pathname }))
        this.setState(() => ({ zoom: undefined }))
    }

    // eslint-disable-next-line react/no-deprecated
    componentDidMount() {
        this.toggleMenuBar = this.toggleMenuBar.bind(this)
        this.routeToPage = this.routeToPage.bind(this)
        this.onBackButtonEvent = this.onBackButtonEvent.bind(this)
        this.alertOrientation = this.alertOrientation.bind(this)
        this.alertOrientation()
        const startPath = window.location.pathname
        this.routeToPage(startPath)
        window.addEventListener('popstate', this.onBackButtonEvent)
        window.addEventListener('resize', this.alertOrientation)
    }

    componentWillLeave() {
        window.removeEventListener('resize', this.alertOrientation)
    }

    routeToPage = (nextRoute) => {
        this.setState(() => ({
            currentRoute: nextRoute,
            menuBarOpen: false,
        }))

        document.getElementById('footer')?.classList?.toggle('fade')

        setTimeout(function () {
            document.getElementById('footer')?.classList?.toggle('fade')
        }, 1000)
    }

    alertOrientation = () => {
        this.setState({ footerHeight: getFooterSize() })
    }

    toggleMenuBar() {
        this.setState((state) => ({ menuBarOpen: !state.menuBarOpen }))
    }

    render() {
        document.documentElement.style.setProperty('--footer-height', String(this.state.footerHeight) + 'px')
        return (
            <>
                <div className="App" id="main">
                    <Helmet>
                        <title>Alter Fährhof</title>
                        <meta
                            name="description"
                            content="Im malerischen Bad Dürrenberg an der Saale erwartet Sie der Alte Fährhof, ein kürzlich renovierter Gebäudekomplex, der moderne Eleganz und gemütlichen Wohnflair verbindet. Sieben stilvolle Reihenhäuser und ein großzügiges Mehrfamilienhaus bieten nicht nur ein Zuhause, sondern eine Atmosphäre des Wohlbefindens. Durchdacht konzipierte Mietwohnungen schaffen ein helles und angenehmes Ambiente, während die ruhige Flusslage für ein inspirierendes Wohngefühl sorgt. Entdecken Sie im Alten Fährhof eine Gemeinschaft, die Charme und Lebensqualität in den Mittelpunkt stellt."
                        ></meta>
                        <meta
                            name="keywords"
                            content="Bad Dürrenberg, Dürrenberg, Wohnung mieten, Mieten Bad Dürrenberg, Mieten Leuna, Alter Fährhof, Faehrhof, Leuna, Mietwohnung, Mietwohnung Bad Dürrenberg, Mietwohnung Leuna, Immobilien, Immobilien Bad Dürrenberg, Immobilien Leuna, Wohnen an der Saale, Saale, Kurort, Kurort Wohnungen, Stadtwohnung, Leuna Industrie, Grünflächen, Natur, Industriekultur, Exklusives Wohnen, Villenlage, Erholung, Naherholungsgebiet, Wohnen im Grünen, Zentrale Lage, Verkehrsanbindung, Regionale Anbindung, Wohnqualität, Hochwertiges Wohnen, Stadtrand Wohnen"
                        ></meta>
                        <meta property="og:title" content="Alter Fährhof" />
                        <meta
                            property="og:description"
                            content="Im malerischen Bad Dürrenberg an der Saale erwartet Sie der Alte Fährhof, ein kürzlich renovierter Gebäudekomplex, der moderne Eleganz und gemütlichen Wohnflair verbindet. Sieben stilvolle Reihenhäuser und ein großzügiges Mehrfamilienhaus bieten nicht nur ein Zuhause, sondern eine Atmosphäre des Wohlbefindens. Durchdacht konzipierte Mietwohnungen schaffen ein helles und angenehmes Ambiente, während die ruhige Flusslage für ein inspirierendes Wohngefühl sorgt. Entdecken Sie im Alten Fährhof eine Gemeinschaft, die Charme und Lebensqualität in den Mittelpunkt stellt."
                        />
                        <meta property="og:image" content="https://res.cloudinary.com/dqav4uxkf/image/upload/v1692898652/konrad/faehrhof/imgs/root/main_2.jpg" />
                        <meta property="og:url" content="www.alter-faehrhof.de" />
                        <meta property="og:type" content="website" />
                    </Helmet>
                    <div style={{ height: '100%' }}>
                        <PathToRoute router={this.routeToPage} cRoute={this.state.currentRoute} />
                        <NavigateTo router={this.routeToPage} cRoute={this.state.currentRoute} />
                        <ContainedNavBar toggleMenuBar={this.toggleMenuBar} menuBarOpen={this.state.menuBarOpen} currentRoute={this.state.currentRoute} />
                        <ButtonToTop />
                        <Wrapper>
                            <TabSEO>
                                <main id="page-wrap" style={{ minHeight: '100vh' }}>
                                    <CookieConsentComponent />
                                    <Suspense fallback={<LoaderCP />}>
                                        <Routes>
                                            <Route path="/" element={<HomePage visit={this.state.homeVisited} visitPage={this.visitPage} cookie={this.showCookieConsent} />} />
                                            <Route path="wohnungen">
                                                <Route index={true} element={<Floors />} />
                                            </Route>
                                            <Route path="wohnungen/reihenhaus">
                                                <Route index={true} element={<TerracedPage />} />
                                                <Route path=":index" element={<FlatPage />} />
                                            </Route>
                                            <Route path="lage">
                                                <Route index={true} element={<LocationPage />} />
                                            </Route>
                                            <Route path="projekte">
                                                <Route index={true} element={<OtherProjectsPage />} />
                                            </Route>
                                            <Route path="wohnungen/haupthaus">
                                                <Route index={true} element={<MainHousePage />} />
                                                <Route path=":index" element={<FlatPage />} />
                                            </Route>
                                            <Route path="wohnungen/torbogenzimmer" element={<ArchwayPage />} />
                                            <Route path="bauverlauf" element={<ConstructionPage />} />
                                            <Route path="geschichte" element={<HistoryPage />} />
                                            <Route path="kontakt" element={<ContactPage />} />
                                            <Route path="impressum" element={<ImpressumPage />} />
                                            <Route path="datenschutz" element={<DataSecurityPage />} />
                                            <Route path="*" element={<ErrorCP />} />
                                        </Routes>
                                    </Suspense>
                                </main>
                            </TabSEO>
                        </Wrapper>
                        <Footer router={this.routeToPage} />
                    </div>
                </div>
            </>
        )
    }
}

export default App
