// TypeScript does not support enums in JavaScript files

const Flat_Wrapper_Image_Description = {
    door: 'Eingangstür',
    eingang: 'Eingangsbereich',
    kind: 'Kinderzimmer',
    kind_1: 'Kinderzimmer 1',
    kind_2: 'Kinderzimmer 2',
    eltern: 'Elternschlafzimmer',
    elternVR: 'Vorraum Elternschlafzimmer',
    elternBG: "Angrenzender Abstellraum",
    wc: 'Gäste-WC',
    wohn: 'Wohnzimmer',
    bad: 'Badezimmer',
    kueche: 'Küche',
    flur: 'Flur',
    flur_eg: 'Flur Eingangsbereich',
    flur_og: 'Flur Obergeschoss',
    flur_dg: 'Flur Dachgeschoss',
    balkon: 'Balkon',
    terrasse: 'Terrasse',
    treppe: 'Treppe',
    abstell: 'Abstellraum',
    abstell_technik: 'Abstellraum mit Heizung',
    ausblick: 'Ausblick',
};


module.exports = Flat_Wrapper_Image_Description