/* eslint-disable no-useless-concat */
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Fade } from 'react-awesome-reveal'

import { headerStyles } from '../../styling/header.jsx'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { IoMenuOutline, IoCloseOutline } from 'react-icons/io5'
import React, { useState, useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import '../../styling/header.css'
import ListGroup from 'react-bootstrap/ListGroup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Desktop, Mobile, getWindowOrientation } from '../../helpers/displayHandler'
import ImageRes from '../../helpers/imageResource'
import { useScrollBlock } from '../../helpers/scrollHandler'
/**
 * Import Faehrhof Logos
 */


import { Network } from '../../network/serviceHandler.jsx'

import { CURRENT_CONFIG } from '../../configs/siteConfiguration'

const config = CURRENT_CONFIG;

const logoShortWhite = { name: 'logo_short_white', folder: ['imgs', 'logos'] }
const logoShortBlack = { name: 'logo_short', folder: ['imgs', 'logos'] }

const data = {logos: {white: logoShortWhite, black: logoShortBlack}}

function ContainedNavBar(props) {
    return (
        <>
            <Desktop>
                <DeskView cRoute={props.currentRoute} data={data} toggleMenuBar={props.toggleMenuBar} />
            </Desktop>
            <Mobile>
                <MobileView cRoute={props.currentRoute} data={data} />
            </Mobile>
        </>
    )
}

export default ContainedNavBar

/**
 * Desk Footer View
 * Split 3 Columns
 * @param {*} props holds "cRoute" that defines the current Page Route
 * @return {*}
 */
function DeskView(props) {
    const [ownRoute, setOwnRoute] = useState('')
    const [show, setShow] = useState(false)
    const [blockScroll, allowScroll] = useScrollBlock()

    // eslint-disable-next-line
    const handleMoveMouse = useCallback((e) => {
        if (show) {
            let mousePosX = (e.pageX / window.innerWidth) * 100
            let imMove = document.getElementById('imageMoveNav')
            if (imMove !== null) {
                imMove.style.right = String(mousePosX.toFixed(0) * -0.5 + 'px')
            }
        }
    })

    const handleClose = () => {
        allowScroll()
        setShow(false)
    }

    const handleMenuClick = () => {
        handleClose()
    }

    // eslint-disable-next-line
    const handleIconChange = useCallback(() => {
        if (props.cRoute === '/') {
            document.getElementById('imageBlack').style.opacity = 0
            // document.getElementById("imageBlack").style.display = "none";
            document.getElementById('imageWhite').style.opacity = 1
            document.getElementById('imageWhite').style.display = 'block'
            document.getElementById('toggler').style.color = 'white'
            document.getElementById('toggler').style.filter = 'drop-shadow(6px 5px 4px rgba(50, 50, 50, .7))'
        } else {
            document.getElementById('imageBlack').style.display = 'block'

            // document.getElementById("imageWhite").style.display = "none";
            document.getElementById('imageBlack').style.opacity = 1
            document.getElementById('imageWhite').style.opacity = 0
            document.getElementById('toggler').style.color = 'black'
            document.getElementById('toggler').style.filter = ''
        }
    })

    const handleRoute = () => {
        if (ownRoute !== props.cRoute) {
            handleIconChange()
            setOwnRoute(props.cRoute)
        }
    }

    React.useEffect(() => {
        handleIconChange()
        handleRoute()
        window.addEventListener('mousemove', handleMoveMouse)
        return () => window.removeEventListener('mousemove', handleMoveMouse)
    })

    const handleShow = () => {
        setShow(true)
        blockScroll()
    }

    const image = { name: 'terraced_8', folder: ['imgs', 'root'] }

    return (
        <>
            <header id="header" className={`${props.cRoute !== '/' ? 'header-D' : 'headerHome-D'}`}>
                <div>
                    <Navbar expand="{xxl, lg, ml}">
                        <Navbar.Brand className="mx-auto" style={headerStyles.desktop.logo.brand}>
                            <NavLink className="headerLink" to="/" onClick={() => handleMenuClick('/')}>
                                <span className="imageSpanWrapper">
                                    <div className="imageDivWrapper">
                                        <img
                                            id="imageBlack"
                                            src={Network.getPageImage(props.data.logos.black, { uncompressed: true }, { svg: true })}
                                            className={'imageLogoFadeInOut '}
                                            style={headerStyles.desktop.logo.image}
                                            alt=""
                                        />
                                        <img
                                            id="imageWhite"
                                            src={Network.getPageImage(props.data.logos.white, { uncompressed: true }, { svg: true })}
                                            className={'imageLogoFadeInOut '}
                                            style={headerStyles.desktop.logo.image}
                                            alt=""
                                        />
                                    </div>
                                </span>
                            </NavLink>
                        </Navbar.Brand>

                        <Navbar.Toggle className="mx-auto" style={headerStyles.desktop.logo.toggle}>
                            <IoMenuOutline
                                onClick={() => {
                                    handleShow()
                                }}
                                id="toggler"
                                className={'toggler'}
                                size={60}
                                style={headerStyles.desktop.logo.menu}
                            />
                        </Navbar.Toggle>

                        <Navbar.Offcanvas id="offcanvasNavbar" expand="{sm,xl,xxl}" show={show} aria-labelledby="offcanvasNavbarLabel" className="offcanvas_Main">
                            <Offcanvas.Header className="offcanvas_Header_D">
                                <div className="offcanvas_Container">
                                    <Navbar.Toggle onClick={handleClose} className="offcanvas_Header_Toggle">
                                        <IoCloseOutline size={60} color={'white'} style={headerStyles.desktop.offCanvasMenu} />
                                    </Navbar.Toggle>
                                </div>
                            </Offcanvas.Header>
                            <Offcanvas.Body className="offcanvas_body_Desktop">
                                <Nav
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '100%',
                                        zIndex: '20',
                                    }}
                                >
                                    <ListGroup style={{ paddingLeft: '6vw' }}>
                                        <ListGroup.Item>
                                            <Fade triggerOnce="true" direction="up" damping={0.5} delay={100} duration={750}>
                                                <>
                                                    <NavLink
                                                        onClick={() => {
                                                            handleMenuClick('/')
                                                        }}
                                                        className="headerLink bg-textAnim"
                                                        to="/"
                                                        style={{
                                                            fontSize: '38pt', // "calc(12pt + 3vw)",
                                                        }}
                                                    >
                                                        {config.website.name}
                                                    </NavLink>
                                                    <div className="headerLinkSM-D subelement">
                                                        <Row>
                                                            <Col md="auto" style={{ paddingTop: '1vh' }}>
                                                                <NavLink
                                                                    onClick={() => {
                                                                        handleMenuClick('/lage')
                                                                    }}
                                                                    className="headerLinkSM-D sm-textAnim"
                                                                    to="/lage"
                                                                >
                                                                    Lage
                                                                </NavLink>
                                                            </Col>
                                                            <Col
                                                                md="auto"
                                                                style={{
                                                                    paddingTop: '1vh',
                                                                    marginLeft: '1.5vw',
                                                                }}
                                                            >
                                                                <NavLink
                                                                    onClick={() => {
                                                                        handleMenuClick('/geschichte')
                                                                    }}
                                                                    className="headerLinkSM-D sm-textAnim"
                                                                    to="/geschichte"
                                                                >
                                                                    Geschichte
                                                                </NavLink>
                                                            </Col>
                                                            <Col
                                                                md="auto"
                                                                style={{
                                                                    marginLeft: '1.5vw',
                                                                    paddingTop: '1vh',
                                                                }}
                                                            >
                                                                <NavLink
                                                                    onClick={() => {
                                                                        handleMenuClick('/Bauverlauf')
                                                                    }}
                                                                    className="headerLinkSM-D sm-textAnim"
                                                                    to="/bauverlauf"
                                                                >
                                                                    Bauverlauf
                                                                </NavLink>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </>
                                            </Fade>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Fade triggerOnce="true" direction="up" cascade="true" damping={0.5} delay={110} duration={700}>
                                                <>
                                                    <NavLink
                                                        to="/wohnungen"
                                                        onClick={() => {
                                                            handleMenuClick('/wohnungen')
                                                        }}
                                                        className="headerLink bg-textAnim "
                                                        style={{
                                                            fontSize: '38pt', // "calc(12pt + 3vw)",
                                                            paddingTop: '0.75em',
                                                            left: '-10px',
                                                        }}
                                                    >
                                                        Mietwohnungen
                                                    </NavLink>
                                                    <div className="headerLinkSM-D subelement ">
                                                        <Row>
                                                            <Col
                                                                md="auto"
                                                                style={{
                                                                    marginRight: '1.5vw',
                                                                    paddingTop: '1vh',
                                                                }}
                                                            >
                                                                <NavLink
                                                                    onClick={() => {
                                                                        handleMenuClick('/wohnungen/reihenhaus')
                                                                    }}
                                                                    className="headerLinkSM-D sm-textAnim"
                                                                    to="/wohnungen/reihenhaus"
                                                                >
                                                                    Reihenhäuser
                                                                </NavLink>
                                                            </Col>
                                                            <Col
                                                                md="auto"
                                                                style={{
                                                                    marginRight: '1.5vw',
                                                                    paddingTop: '1vh',
                                                                }}
                                                            >
                                                                <NavLink
                                                                    onClick={() => {
                                                                        handleMenuClick('/wohnungen/haupthaus')
                                                                    }}
                                                                    className="headerLinkSM-D sm-textAnim"
                                                                    to="/wohnungen/haupthaus"
                                                                >
                                                                    Haupthaus
                                                                </NavLink>
                                                            </Col>
                                                            <Col
                                                                md="auto"
                                                                style={{
                                                                    paddingTop: '1vh',
                                                                }}
                                                            >
                                                                <NavLink
                                                                    onClick={() => {
                                                                        handleMenuClick('/wohnungen/torbogenzimmer')
                                                                    }}
                                                                    className="headerLinkSM-D sm-textAnim"
                                                                    to="/wohnungen/torbogenzimmer"
                                                                >
                                                                    Torbogenzimmer
                                                                </NavLink>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </>
                                            </Fade>
                                        </ListGroup.Item>
                                    </ListGroup>

                                    <ListGroup
                                        style={{
                                            position: 'absolute',
                                            bottom: '13vh',
                                            paddingLeft: '6vw',
                                        }}
                                    >
                                        <ListGroup.Item>
                                            <Fade triggerOnce="true" direction="up" cascade="true" damping={0.5} delay={100} duration={750}>
                                                <NavLink
                                                    onClick={() => {
                                                        handleMenuClick('/kontakt')
                                                    }}
                                                    className="headerLink bg-textAnim "
                                                    to="/kontakt"
                                                    style={{
                                                        fontSize: '28pt',
                                                        left: '-10px',
                                                    }}
                                                >
                                                    Kontakt
                                                </NavLink>
                                            </Fade>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Fade triggerOnce="true" direction="up" cascade="true" damping={0.5} delay={90} duration={750}>
                                                <NavLink
                                                    onClick={() => {
                                                        handleMenuClick('/projekte')
                                                    }}
                                                    className="headerLink bg-textAnim "
                                                    to="/projekte"
                                                    style={{
                                                        fontSize: '24pt',
                                                        left: '-10px',
                                                    }}
                                                >
                                                    Weitere Projekte
                                                </NavLink>
                                            </Fade>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Nav>
                                <div className="previewNavImage-Wrapper">
                                    <div id="blackSlide" className="slide" />
                                    <div className="imagePanWrapper">
                                        <ImageRes
                                            imgId="imageMoveNav"
                                            id="imageMoveNav"
                                            alt="Vorschaubild"
                                            className="previewNavImage"
                                            lazy={true}
                                            noEffect={true}
                                            addStyle={[
                                                { type: 'height', value: 'auto' },
                                                { type: 'minHeight', value: '100%' },
                                                { type: 'minWidth', value: '120%' },
                                                { type: 'objectFit', value: 'cover' },
                                            ]}
                                            height={130}
                                            placeholderImg={Network.getPageImage(image, {
                                                placeholder: true,
                                            })}
                                            src={Network.getPageImage(image, {
                                                navImage: true,
                                            })}
                                        />
                                    </div>
                                    <div className="contactDetails">
                                        <Row style={{ height: '100%' }}>
                                            <Col size="6" style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                                <Fade triggerOnce="true" direction="up" cascade="true" damping={0.05} delay={150}>
                                                    <a href={config.telephone.href} className="offcanvas-infoText">
                                                        {config.telephone.plain}
                                                    </a>
                                                </Fade>
                                            </Col>
                                            <Col size="6" style={{ alignSelf: 'center', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                                <Fade triggerOnce="true" direction="up" cascade="true" damping={0.05} delay={150}>
                                                    <a href={config.email.href + config.email.email_generalSubject} className="offcanvas-infoText">
                                                        {config.email.plain}
                                                    </a>
                                                </Fade>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Navbar>
                </div>
            </header>
        </>
    )
}

/**
 * Desk Footer View
 * Split 3 Columns
 * @param {*} props defines "cRoute" that holds the value of the current Route
 * @return {*}
 */
function MobileView(props) {
    const [show, setShow] = useState(false)
    const [landscape, setLandscape] = useState(false)
    const [ownRoute, setOwnRoute] = useState('')
    const [blockScroll, allowScroll] = useScrollBlock()

    const handleClose = () => {
        allowScroll()
        setShow(false)
    }

    const handleMenuClick = () => {
        handleClose()
    }

    const handleIconChange = () => {
        document.getElementById('imageWhite').style.transition = 'opacity 0.25 ease-in-out'
        document.getElementById('imageBlack').style.transition = 'opacity 0.25 ease-in-out'
        if (props.cRoute === '/') {
            document.getElementById('imageBlack').style.opacity = 0
            document.getElementById('imageBlack').style.display = 'none'
            document.getElementById('imageWhite').style.opacity = 1

            document.getElementById('imageWhite').style.display = 'block'
            document.getElementById('toggler').style.color = 'white'
            document.getElementById('toggler').style.filter = 'drop-shadow(6px 5px 4px rgba(50, 50, 50, .7))'
        } else {
            document.getElementById('imageBlack').style.display = 'block'

            document.getElementById('imageWhite').style.display = 'none'
            document.getElementById('imageBlack').style.opacity = 1
            document.getElementById('imageWhite').style.opacity = 0
            document.getElementById('toggler').style.color = 'black'
            document.getElementById('toggler').style.filter = ''
        }
    }

    const handleRoute = () => {
        if (ownRoute !== props.cRoute) {
            handleIconChange()
            setOwnRoute(props.cRoute)
        }
    }

    const handleResize = () => {
        if (getWindowOrientation() === 'Landscape') {
            setLandscape(true)
        } else {
            setLandscape(false)
        }
    }

    React.useEffect(() => {
        handleIconChange()
        handleRoute()
        handleResize()
        window.addEventListener('resize', handleResize)
    })

    const handleShow = () => {
        setShow(true)
        blockScroll()
    }

    return (
        <header id="header" className="header">
            <Navbar collapseOnSelect expand="lg">
                <span className="imageSpanWrapper">
                    <NavLink className="headerLinkToggle" to="/" onClick={() => handleMenuClick('/')}>
                        <div className="imageDivWrapper">
                            <img
                                id="imageWhite"
                                src={Network.getPageImage(props.data.logos.white, { uncompressed: true }, { svg: true })}
                                className={'imageLogoFadeInOut '}
                                style={headerStyles.mobile.logo.image}
                                alt=""
                            />
                            <img
                                id="imageBlack"
                                src={Network.getPageImage(props.data.logos.black, { uncompressed: true }, { svg: true })}
                                className={'imageLogoFadeInOut '}
                                style={headerStyles.mobile.logo.image}
                                alt=""
                            />
                        </div>
                    </NavLink>
                </span>

                <Navbar.Toggle
                    onClick={() => {
                        handleShow()
                    }}
                    style={headerStyles.mobile.logo.toggle}
                >
                    <IoMenuOutline id="toggler" className={'toggler'} size={60} style={headerStyles.mobile.menu} />
                </Navbar.Toggle>

                <Navbar.Offcanvas id="offcanvasNavbar" data-bs-scroll="false" placement="start" show={show} className="offcanvas_Main">
                    <Offcanvas.Header className="offcanvas_Header">
                        <div className="offcanvas_Container">
                            <Navbar.Toggle onClick={handleClose} className="offcanvas_Header_Toggle">
                                <IoCloseOutline size={60} color={'white'} style={headerStyles.mobile.menu} />
                            </Navbar.Toggle>
                        </div>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="offcanvas_body_Mobile">
                        <Nav className="justify-content-center flex-grow">
                            <ListGroup style={{ alignItems: 'center' }}>
                                <ListGroup.Item>
                                    <Fade triggerOnce="true" direction="up" damping={0.8} duration={550} delay={25}>
                                        <NavLink
                                            onClick={() => {
                                                handleMenuClick('/')
                                            }}
                                            className="headerLink sm-textAnim"
                                            to="/"
                                        >
                                            {config.website.name}
                                        </NavLink>
                                    </Fade>
                                </ListGroup.Item>
                                {!landscape && (
                                    <ListGroup.Item>
                                        <Fade triggerOnce="true" damping={0.3} duration={600} direction="up" delay={75}>
                                            <div className="headerLinkSM-M subelement" style={{ marginBottom: '2vh' }}>
                                                <Row>
                                                    <Col
                                                        style={{
                                                            marginRight: '3vw',
                                                            paddingTop: '1vh',
                                                            display: 'inline-table',
                                                        }}
                                                    >
                                                        <NavLink
                                                            onClick={() => {
                                                                handleMenuClick('/bauverlauf')
                                                            }}
                                                            className="headerLinkSM-M sm-textAnim"
                                                            to="/bauverlauf"
                                                        >
                                                            Bauverlauf
                                                        </NavLink>
                                                    </Col>
                                                    <Col
                                                        style={{
                                                            paddingTop: '1vh',
                                                            display: 'inline-table',
                                                        }}
                                                    >
                                                        <NavLink
                                                            onClick={() => {
                                                                handleMenuClick('/geschichte')
                                                            }}
                                                            className="headerLinkSM-M sm-textAnim"
                                                            to="/geschichte"
                                                        >
                                                            Geschichte
                                                        </NavLink>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        style={{
                                                            paddingTop: '1vh',
                                                            display: 'inline-table',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <NavLink
                                                            onClick={() => {
                                                                handleMenuClick('/lage')
                                                            }}
                                                            className="headerLinkSM-M sm-textAnim"
                                                            to="/lage"
                                                        >
                                                            Lage
                                                        </NavLink>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Fade>
                                    </ListGroup.Item>
                                )}
                                <ListGroup.Item>
                                    <Fade triggerOnce="true" direction="up" cascade="true" damping={0.8} duration={550} delay={25}>
                                        <NavLink
                                            onClick={() => {
                                                handleMenuClick('/wohnungen')
                                            }}
                                            className="headerLink sm-textAnim"
                                            to="/wohnungen"
                                        >
                                            Mietwohnungen
                                        </NavLink>
                                    </Fade>
                                </ListGroup.Item>
                                {!landscape && (
                                    <ListGroup.Item>
                                        <Fade triggerOnce="true" direction="up" damping={0.8} duration={600} delay={75}>
                                            <div className="headerLinkSM-M subelement" style={{ marginBottom: '2vh' }}>
                                                <Row>
                                                    <Col
                                                        style={{
                                                            marginRight: '3vw',
                                                            paddingTop: '1vh',
                                                            display: 'inline-table',
                                                        }}
                                                    >
                                                        <NavLink
                                                            onClick={() => {
                                                                handleMenuClick('/wohnungen/reihenhäuser')
                                                            }}
                                                            className="headerLinkSM-M sm-textAnim"
                                                            to="/wohnungen/reihenhaus"
                                                        >
                                                            Reihenhäuser
                                                        </NavLink>
                                                    </Col>
                                                    <Col
                                                        style={{
                                                            paddingTop: '1vh',
                                                            display: 'inline-table',
                                                        }}
                                                    >
                                                        <NavLink
                                                            onClick={() => {
                                                                handleMenuClick('/wohnungen/haupthaus')
                                                            }}
                                                            className="headerLinkSM-M sm-textAnim"
                                                            to="/wohnungen/haupthaus"
                                                        >
                                                            Haupthaus
                                                        </NavLink>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        style={{
                                                            paddingTop: '1vh',
                                                            display: 'inline-table',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <NavLink
                                                            onClick={() => {
                                                                handleMenuClick('/wohnungen/torbogenzimmer')
                                                            }}
                                                            className="headerLinkSM-M sm-textAnim"
                                                            to="/wohnungen/torbogenzimmer"
                                                        >
                                                            Torbogenzimmer
                                                        </NavLink>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Fade>
                                    </ListGroup.Item>
                                )}
                            </ListGroup>

                            <ListGroup style={{ position: 'absolute', bottom: '5vh' }}>
                                <Fade triggerOnce="true" direction="up" damping={0.05} duration={550} delay={100}>
                                    <ListGroup.Item style={{ textAlignLast: 'center' }} className="offcanvas-contact_Mobile">
                                        <NavLink
                                            onClick={() => {
                                                handleMenuClick('/kontakt')
                                            }}
                                            className="headerLink sm-textAnim"
                                            style={{ fontSize: 'calc(22pt + (5vh - 5.5vw) / 5.5)' }}
                                            to="/kontakt"
                                        >
                                            Kontakt
                                        </NavLink>
                                    </ListGroup.Item>

                                    <ListGroup.Item className="offcanvas-ListItem_Mobile" style={{ textAlignLast: 'center' }}>
                                        <a href={config.telephone.href} className="offcanvas-infoText_Mobile">
                                            {config.telephone.plain}
                                        </a>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="offcanvas-ListItem_Mobile" style={{ paddingBottom: '0.9em' }}>
                                        <a href={config.email.href + config.email._generalSubject} className="offcanvas-infoText_Mobile">
                                            {config.email.plain}
                                        </a>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Fade triggerOnce="true" direction="up" damping={0.8} duration={550} delay={25}>
                                            <NavLink
                                                onClick={() => {
                                                    handleMenuClick('/projekte')
                                                }}
                                                className="headerLink sm-textAnim"
                                                to="/projekte"
                                                style={{ textAlignLast: 'center', fontSize: '16pt' }}
                                            >
                                                Weitere Projekte
                                            </NavLink>
                                        </Fade>
                                    </ListGroup.Item>
                                </Fade>
                            </ListGroup>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
        </header>
    )
}
