/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/ban-types */

import React from 'react'
import { CSSProperties, useCallback, useEffect, useState } from 'react'
import { Boop } from '../helpers/boop'
import '../styling/boop.css'
import { getWindowOrientation } from './displayHandler'
import { ImageLoadEffect } from './imageLoadEffect'

export const getOptions = (version: string, options: { widescreen?: boolean; fullscreen?: boolean }) => {
    if (version === 'Desktop' && options?.widescreen) return { widescreen: true }
    if (version === 'Desktop') return { higher: true }
    if (version === 'Mobile' && options?.fullscreen) return { middle: true }
    return { lower: true }
}

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    placeholderImg?: string
    onCClick?: Function
    afterCLoad?: Function
    lazy?: boolean
    swiperLazy?: boolean
    swiperLazyFullscreen: boolean
    preFetchCWhite?: boolean
    imgId?: string
    height?: number
    /// example prop: addStyle={[{type: "maxWidth", value: "100%"}]}
    addStyle?: [{ [key: string]: string }]
    preload?: boolean
    /// image Hover Cursor Animation
    isBooped?: boolean
    /// image effect slide up
    noEffect?: boolean
}

export default ({ src, alt, placeholderImg, onCClick, afterCLoad, swiperLazy, swiperLazyFullscreen, lazy, addStyle, imgId, isBooped, noEffect, ...props }: ImageProps) => {
    /// On Load Image Function
    const [imgSrc, setSrc] = useState(placeholderImg || src)
    const [isLoaded, setIsLoaded] = useState(false)
    const onLoad = useCallback(() => {
        setSrc(src)
        setIsLoaded(true)
        if (afterCLoad) {
            afterCLoad()
        }
    }, [src])
    useEffect(() => {
        setIsLoaded(false)
        const img = new Image()
        img.src = placeholderImg as string
        // img.setAttribute("data-src", src as string);
        img.addEventListener('load', onLoad)
        return () => {
            img.removeEventListener('load', onLoad)
        }
    }, [src, onLoad])

    /// Default Style Attributes
    const style: CSSProperties = {
        position: 'relative',
        width: getWindowOrientation() === 'Portrait' ? 'auto' : '100%',
        height: getWindowOrientation() === 'Portrait' ? '100%' : 'auto',
        maxHeight: getWindowOrientation() === 'Portrait' ? '100vh' : 'auto',
        maxWidth: getWindowOrientation() === 'Portrait' ? 'auto' : '100vw',
    }
    if (addStyle) {
        addStyle?.forEach((element: { [key: string]: string }) => {
            if (element['type']) {
                switch (element.type) {
                    case 'maxWidth':
                        style['maxWidth'] = element.value
                        break
                    case 'minWidth':
                        style['minWidth'] = element.value
                        break
                    case 'width':
                        style['width'] = element.value
                        break
                    case 'height':
                        style['height'] = element.value
                        break
                    case 'minHeight':
                        style['minHeight'] = element.value
                        break
                    case 'maxHeight':
                        style['maxHeight'] = element.value
                        break
                    case 'boxShadow':
                        style['boxShadow'] = element.value
                        break
                    case 'margin':
                        style['margin'] = element.value
                        break
                    case 'position':
                        if (element.value === ('absolute' || 'relative')) {
                            style['position'] = element.value
                        }
                        break
                    case 'cursor':
                        style['cursor'] = element.value
                        break
                    case 'objectFit':
                        if (element.value === 'cover') {
                            style['objectFit'] = element.value
                        }
                        break
                    case 'overflow':
                        if (element.value === 'hidden') {
                            style['overflow'] = element.value
                        }
                        break
                    default:
                        break
                }
            }
        })
    }

    /// Lazy Image Loading

    if (lazy) {
        const _img = <ImageLoadEffect alt={alt ? alt : imgSrc} src={imgSrc} srcSet={`${imgSrc}`} id={imgId} style={style} onCClick={onCClick} isLoaded={isLoaded} noEffect={noEffect} />
        /// Build imgView
        const imgView = (
            <>
                {isBooped ? (
                    <>
                        <div className={'boop-Overlay'}>{_img}</div>
                    </>
                ) : (
                    <>{_img}</>
                )}
            </>
        )

        return (
            <>
                {isBooped ? (
                    <>
                        <Boop>{imgView}</Boop>
                    </>
                ) : (
                    <>{imgView}</>
                )}
            </>
        )

        /// Swiper Images
    } else if (swiperLazy) {
        return (
            <>
                <div className="swiper-lazy">
                    <div className="swiper-zoom-container" data-swiper-zoom="5">
                        <img
                            id={imgId}
                            {...props}
                            className="swiper-zoom-target"
                            alt={alt ? alt : src}
                            onClick={() => {
                                if (onCClick) {
                                    onCClick()
                                }
                            }}
                            style={style}
                            src={imgSrc}
                            srcSet={`${imgSrc}`}
                        />
                    </div>
                </div>

                <div className={swiperLazyFullscreen === undefined ? 'swiper-lazy-preloader' : 'swiper-lazy-preloader swiper-lazy-preloader-white'}></div>
            </>
        )
    }
    /// Return default
    else {
        return <ImageLoadEffect alt={imgSrc} src={imgSrc} srcSet={`${imgSrc}`} style={style} id={imgId} noEffect={noEffect} onCClick={onCClick} isLoaded={isLoaded} />
    }
}
