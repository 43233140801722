export enum Location_Wrapper {
    HeadInfo = 0,
    Descrb,
    BottomText,
    BottomImage,
    CenterImage,
}

export function getLocationInfoWrapped(data: Location_Wrapper) {
    switch (data) {
        case Location_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Lage'],
                    titleSlash: true,
                    subTitle: ['Nur 30 Min. von Leipzig und Halle entfernt.'],
                    links: [
                        {
                            text: `Einzigartiges Wohnen im Kurort`,
                            to: '/lage',
                            slash: false,
                        },
                    ],
                },
                image: {
                    name: 'main_12',
                    folder: ['imgs', 'root'],
                    alt: 'Borlachturm Bad Dürrenberg',
                },
                content: [
                    `Erleben Sie das Beste aus beiden Welten - die Nähe zur Stadt und die entspannte Atmosphäre des Kurorts.`,
                    `Der exklusive Wohnort liegt nur einen Katzensprung von Halle und Leipzig entfernt. `+
                    `Hier erwarten Sie zahlreiche Möglichkeiten für Naherholung und Freizeitaktivitäten, während Sie in nur 30 Minuten in einer der Großstädte sind. `+
                    `Kommen und erleben Sie die perfekte Kombination aus schönem Wohnen, Entspannung und attraktiver Anbindung an Halle und Leipzig.`,
                ],
            }

        case Location_Wrapper.Descrb:
            return {
                image: {
                    name: 'sul_6',
                    folder: ['imgs', 'location'],
                    alt: 'Konzeptbild',

                    // source: {
                    //     text: 'Quelle Saaleradweg e.V. Foto V.Grätsch',
                    //     link: 'https://www.saaleradweg.de/',
                    // },
                },
                content: [
                    'Über den Kurort',
                    `Bad Dürrenberg, ein beliebter Kurort in Sachsen-Anhalt, bietet ein umfangreiches Kulturangebot sowie zahlreiche Möglichkeiten zur Naherholung. ` +
                        `Der Saaleradweg lädt zum Radfahren und Spazierengehen ein und die Saale bietet Möglichkeiten zum Angeln, Bootfahren und Schwimmen. ` +
                        `Die Historische Sehenswürdigkeiten wie der angrenzende Borlachturm, ` +
                        `die Parkanlagen mit dem längsten Gradierwerk in Deutschland bieten ebenfalls Möglichkeiten zur Entspannung und Erholung.`,
                ],
                links: [
                    {
                        text: 'Erkunde den Saaleradweg',
                        link: 'https://www.saaleradweg.de/etappen/etappe-7/',
                        scrollTo: false,
                        target: true,
                    },
                ],
            }

        case Location_Wrapper.BottomText:
            return {
                content: [
                    'Anbindung an das Umland',
                    `Bad Dürrenberg liegt in unmittelbaren Nähe von Halle und Leipzig, und ist weniger als 30 Minuten von diesen entfernt. `+
                    `Doch nicht nur das: Die Stadt ist auch an das schnelle und bequeme Regionalbahn-Netz angeschlossen. `+
                    `Darüber hinaus ist die Anbindung an den öffentlichen Nahverkehr hervorzuheben, `+
                    `da in direkter Nähe des Alten Fährhofs Bus- und Straßenbahnhaltestellen zu finden sind. Von dort aus verkehren regelmäßig Linien in alle Richtungen.`,
                ],
            }
        case Location_Wrapper.CenterImage:
            return {
                image: {
                    name: 'sul_1',
                    folder: ['imgs', 'location'],
                    alt: 'Torbogenzimmer',

                    source: {
                        text: 'Dguendel',
                        link: 'https://commons.wikimedia.org/wiki/File:Bad_D%C3%BCrrenberg,_das_Gradierwerk-5.jpg',
                        additional: {
                            text: 'CC BY 3.0',
                            link: 'https://creativecommons.org/licenses/by/3.0',
                        },
                    },
                },
            }
        case Location_Wrapper.BottomImage:
            return {
                image: {
                    name: 'connection_2',
                    folder: ['imgs', 'location'],
                    alt: 'Torbogenzimmer',
                },
            }
    }
}
