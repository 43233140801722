import { StyleSheet } from 'react-native-web'

export const headerStyles = StyleSheet.create({
    mobileHeader: {
        zIndex: '1030',
        display: 'block',
        top: '0',
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0)',

        position: 'absolute',
        marginTop: '30pt',
    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },

    mobile: {
        logo: {
            parent: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 0,
            },
            image: {
                height: '40pt',
                marginBottom: 10,
                marginTop: 10,
                // aspectRatio: 3.6646,
                aspectRatio: 0.935,
                display: 'block',
                position: 'absolute',
                justifyContent: 'center',
            },

            toggle: {
                position: 'relative',
                border: 'none',
                marginTop: '0',
            },
        },
        menu: {
            marginLeft: '30px',
        },
    },
    desktop: {
        logo: {
            parent: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 0,
                pointerEvent: 'none',
            },

            image: {
                height: '50pt',
                width: 'auto',
                marginBottom: 15,
                marginTop: 15,
                aspectRatio: 0.935,
                // aspectRatio: 3.6646,
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                pointerEvent: 'none',
            },
            toggle: {
                position: 'relative',
                marginTop: '15px',
                border: 'none',
                cursor: 'auto',
                display: 'contents',
            },
            menu: {
                cursor: 'pointer',
                marginLeft: '40pt',
            },
        },
        offCanvasMenu: {
            filter: 'drop-shadow(3px 3px 3px rgba(0,0,0,0.8))',
        },
        nav: {
            linkRight: {
                paddingLeft: '10vh',
                display: 'table',
                // height: '100px',
                width: '100%',
                textAlign: 'center',
                span: {
                    display: 'table-cell',
                    verticalAlign: 'middle',
                },
            },
            linkLeft: {
                paddingRight: '10vh',
                display: 'table',
                // height: '100px',
                width: '100%',
                textAlign: 'center',
                span: {
                    display: 'table-cell',
                    verticalAlign: 'middle',
                    color: 'white',
                },
            },
        },
    },
    instructions: {
        color: '#888',
        fontSize: 18,
        marginHorizontal: 15,
    },
})
