/* eslint-disable no-case-declarations */
export enum ConstructionPage_Wrapper {
    HeadInfo = 0,
    Comparison,
    Carousel,
    BottomSthElse,
    CenterTextSep,
    ImageSep,
    SlideCompare,
}

export function getConstructionInfoWrapped(data: ConstructionPage_Wrapper) {
    switch (data) {
        case ConstructionPage_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Bauverlauf'],
                    titleSlash: true,
                    links: [
                        {
                            text: 'Rückblick auf die Sanierung',
                            to: '/bauverlauf',
                            slash: false,
                        },
                    ],
                },
                ///
                images: [
                    {
                        description: "Der ehem. 'Saline-Gasthof'",
                        name: 'main_1',
                        folder: ['imgs', 'compare'],
                        alt: 'Saline-Gasthof',
                    },
                    {
                        description: 'Erste große Baufortschritte',
                        name: 'topView_2',
                        folder: ['imgs', 'compare'],
                        alt: 'Baufortschritte',
                    },
                    {
                        description: 'Erstrahlt in neuem Glanz',
                        name: 'main_2',
                        folder: ['imgs', 'root'],
                        alt: 'Heutzutage',
                    },
                ],
                content: ['Willkommen im Bauverlauf.', 'Der Alte Fährhof erstrahlt in neuem Glanz. Diese Seite soll Ihnen einen Einblick in die Fortschritte der Sanierung des Alten Fährhofs geben.'],
            }
        case ConstructionPage_Wrapper.Carousel:
            return {
                images: [{}],
            }
        case ConstructionPage_Wrapper.ImageSep:
            return {
                image: {
                    dscrb: 'Fährstraße',
                    name: 'topView_3',
                    folder: ['imgs', 'compare'],
                    alt: 'Reihenhäuser Straße vorher',
                },
            }
        case ConstructionPage_Wrapper.SlideCompare:
            const _chunks = []
            _chunks.push({
                title: 'Der Alte Fährhof aus der Saale-Sicht',
                description: `Der Alter Fährhof wurde umfassend saniert und präsentiert sich nun in einem völlig neuen Erscheinungsbild. 
                    Insbesondere an der Frontseite des Haupthauses sind die Veränderungen deutlich sichtbar. 
                    Die alten Fenster wurden gegen moderne, dreifach verglaste Holzfenster ausgetauscht. 
                    Die Sandsteinumrandungen der Fenster stechen mit ihrer traumhaften Farbe hervor und setzen einen wunderschönen Kontrast zur Hauptfarbe des Hauses.`,
                images: [
                    {
                        dscrb: 'Fährstraße',
                        name: 'before_1',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'after_1',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                ],
            })
            _chunks.push({
                title: 'Haupthausansicht am Borlachturm',
                description: `Vom Saaleradweg aus bietet sich ein traumhafter Blick auf das Hauptgebäude. Die neue Ansicht enthüllt die originalen Bossen. 
                    Sie treten nun deutlich hervor und erzählen von der langen Geschichte des Hauses. 
                    Besonders beeindruckend sind die Veränderungen an den Gauben und dem Dach, die einen eindrucksvollen Vergleich darstellen.`,
                images: [
                    {
                        dscrb: 'Fährstraße',
                        name: 'before_2',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'after_2',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                ],
            })
            _chunks.push({
                title: 'Der Torbogen über die Zeit',
                description: `Der ehemalige Saline-Gasthof verband einst das Haupthaus und die Reihenhäuser durch einen Torbogen, der als Übergang für Gäste zu den Zimmern diente. Über die Jahre hinweg wurde dieser Torbogen als Wohnraum genutzt und hinter Steinen verborgen. Nun wurde der historische Torbogen wieder freigelegt und erstrahlt in seiner ursprünglichen Pracht. `,
                images: [
                    {
                        dscrb: 'Torbogen',
                        name: 'before_11',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Torbogen',
                        name: 'after_11',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                ],
                links: [
                    {
                        text: 'Mehr zum Torbogenzimmer',
                        link: '/wohnungen/torbogenzimmer',
                        slash: false,
                    },
                ],
            })
            _chunks.push({
                title: 'Ansicht Fährstraße',
                description: `Das Highlight des Alten Fährhofs ist zweifellos der freigelegte, originale Torbogen des historischen Gebäudes. 
                    Einst verband er das Langhaus mit dem Haupthaus und diente als Übergang. Nun bietet er einen stilvollen Zugang zum Innenhof. 
                    Über dem Torbogen erhebt sich das einzigartige Torbogenzimmer.`,
                images: [
                    {
                        dscrb: 'Fährstraße',
                        name: 'before_4',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'after_4',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                ],
                links: [
                    {
                        text: 'Mehr zum Torbogenzimmer',
                        link: '/wohnungen/torbogenzimmer',
                        slash: false,
                    },
                ],
            })
            _chunks.push({
                title: 'Fährstraße - Straßenansicht',
                description: `Der Fährhof aus der Straßensicht zeigt die beeindruckende Verwandlung des Alten Fährhofs.`,
                images: [
                    {
                        dscrb: 'Fährstraße',
                        name: 'before_13',
                        folder: ['imgs', 'compare'],
                        alt: 'Fährstraße oben',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'after_13',
                        folder: ['imgs', 'compare'],
                        alt: 'Fährstraße oben',
                    },
                ],
            })
            _chunks.push({
                title: 'Ansicht Reihenhäuser und Innenhof',
                description: `Die Reihenhäuser und der Innenhof des Alten Fährhofs präsentieren sich nach der Sanierung in einem völlig neuen Licht.`,
                images: [
                    {
                        dscrb: 'Reihenhäuser und Innenhof',
                        name: 'before_12',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Reihenhäuser und Innenhof',
                        name: 'after_12',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                ],
            })
            _chunks.push({
                title: 'Innenhof des Alten Fährhofs',
                description: `Der Innenhof des Alten Fährhofs, einst von üppigem Grün überwuchert, erstrahlt nach der Sanierung in einer atemberaubenden Verwandlung. 
                    Kaum wiederzuerkennen, präsentiert sich der Innenhof nun in einem vollkommen neuen Licht. 
                    Mit gepflegter Gestaltung und angenehmer Atmosphäre verbindet er das Haupthaus und die Reihenhäuser und dient als zentraler Eingangsbereich zu den Häusern. 
                    Die einstigen Garagen werden durch eine moderne Aufteilung aus hochwertigen Garagen und Stellplätzen für Autos und Fahrräder ersetzt.`,
                images: [
                    {
                        dscrb: 'Fährstraße',
                        name: 'before_6',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'after_14',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                ],
            })
            _chunks.push({
                title: 'Haupthaus im Innenhof',
                description:
                    'Vom Innenhof aus bietet sich ein beeindruckender Blick auf das Hauptgebäude des Alten Fährhofs. Der Torbogendurchgang mit Treppenanlage dient als Haupteingang zum Haupthaus.',
                images: [
                    {
                        dscrb: 'Fährstraße',
                        name: 'before_7',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'after_7',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                ],
            })
            _chunks.push({
                title: 'Reihenhäuser im Innenhof',
                description: `Die Reihenhäuser, einst mit grauen Wänden und vernachlässigtem Erscheinungsbild, erstrahlen nun in moderner Optik. 
                    Eine hohe Dämmung sorgt für Komfort und Energieeffizienz. Zusätzlich werden für jedes Reihenhaus großzügige Terrassen geschaffen, 
                    die den Bewohnern Raum für Entspannung und Genuss im Freien bieten.`,
                images: [
                    {
                        dscrb: 'Fährstraße',
                        name: 'before_8',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'terraced_6',
                        folder: ['imgs', 'root'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                ],
            })
            _chunks.push({
                title: 'Reihenhäuser an der Fährstraße',
                description: `Die Reihenhäuser entlang der Fährstraße präsentieren sich nach den umfangreichen Sanierungsarbeiten in einer beeindruckenden Optik. 
                    Die großzügigen Gauben erweitern das Dachgeschoss.`,
                images: [
                    {
                        dscrb: 'Fährstraße',
                        name: 'before_9',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'terraced_9',
                        folder: ['imgs', 'root'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                ],
            })

            return {
                head: {
                    title: ['Interaktiv die Einzigartigkeit des Alten Fährhofs erleben'],
                    subTitle: 'Vergleichen Sie die Perspektiven',
                },
                chunks: _chunks,
            }
        case ConstructionPage_Wrapper.Comparison:
            return {
                images: [
                    {
                        dscrb: 'Fährstraße',
                        name: 'before_1',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'after_1',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'before_2',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'after_2',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'before_4',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'after_4',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'before_6',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'terraced_6',
                        folder: ['imgs', 'root'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'before_7',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'terraced_10',
                        folder: ['imgs', 'root'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'before_8',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'terraced_7',
                        folder: ['imgs', 'root'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'before_9',
                        folder: ['imgs', 'compare'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                    {
                        dscrb: 'Fährstraße',
                        name: 'terraced_5',
                        folder: ['imgs', 'root'],
                        alt: 'Reihenhäuser Straße vorher',
                    },
                ],
                content: {
                    text: ['Der Baukomplex hat sich drastitisch verändert! Erleben Sie den Unterschied der Kernsanierung interaktiv.', 'Wechseln Sie zwischen Heutigem und Vergangenen Perspektiven.'],
                },
            }
        case ConstructionPage_Wrapper.BottomSthElse:
            return null
        case ConstructionPage_Wrapper.CenterTextSep:
            return {
                content: ['Erleben Sie die Unterschiede der Kernsanierung des Alten Fährhofs.'],
                // link: {
                //   text: "Zum Torbogenzimmer",
                //   link: "/wohnungen/torbogenzimmer",
                //   scrollTo: false,
                // },
            }
    }
}
