/* eslint-disable no-case-declarations */
/* eslint-disable no-lone-blocks */
import { Image } from '../../server'
import { flatData } from '../flat/flats_data'
import { Flat_Wrapper, Flat_Wrapper_Levels } from '../flat/flat_class_Wrapped'

/* In progress */
export enum Terraced_Wrapper {
    HeadInfo = 0,
    Descrb,
    TextSep,
    SthElse,
    Carousel,
    BottomSthElse,
    FlatList,
}

export function getTerracedInfoWrapped(data: Terraced_Wrapper) {
    switch (data) {
        case Terraced_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Reihenhäuser'],
                    titleSlash: true,
                    links: [
                        {
                            text: 'Wohnen auf mehreren Etagen',
                            to: '/wohnungen/reihenhaus',
                            slash: false,
                        },
                    ],
                    subTitle: ['Viel Platz für die gesamte Familie'],
                },
                image: {
                    name: 'terraced_11',
                    folder: ['imgs', 'root'],
                    alt: 'Konzeptbild',
                },
                content: {
                    banner: ['ab 76m²', 'bis 109m²'],
                    text: [
                        `Willkommen in den Reihenhäusern des Alten Fährhofs. ` +
                            `Diese einzigartigen Wohnungen erstrecken sich je über zwei oder drei Etagen und beeindrucken mit großzügigen Grundrissen und hochwertiger Ausstattung. ` +
                            `Erleben Sie den Komfort und die Eleganz dieser exklusiven Reihenhäuser und genießen Sie ein außergewöhnliches Wohnerlebnis.`,
                    ],
                    // link: {
                    //     text: 'Direkt zu den Wohnungen',
                    //     link: 'wohnungen',
                    //     scrollTo: true,
                    // },
                },
            }
        case Terraced_Wrapper.Descrb:
            return {
                image: {
                    name: 'terraced_8',
                    folder: ['imgs', 'root'],
                    alt: 'Konzeptbild',
                },
                content: [
                    'Wohnen im Reihenhaus',
                    '- Ein Einzigartiges Wohngefühl',
                    `Die Reihenhäuser bieten Ihnen einen privaten Eingang direkt vom Innenhof aus, erkennbar an den individuell gestalteten Holztüren in verschiedenen Farben. ` +
                        `Jedes Reihenhaus verfügt über eine private Terrasse, die zum gemeinsamen Verweilen im Freien einlädt und eine entspannte Atmosphäre im ruhigen Innenhof bietet. ` +
                        `Im Obergeschoss erwartet Sie Echtholzparkett und eine hochwertige Badausstattung.`,
                ],
                // links: [
                //   {
                //     text: "Erkunde den Innenhof",
                //     link: "/details/innenhof",
                //     scrollTo: false,
                //   },
                // ],
            }
        case Terraced_Wrapper.TextSep:
            return {
                content: ['Durch die Nähe der Saale und des Kurparks bieten sich wundervolle Wege und Möglichkeiten die Natur zu erleben.'],
                link: { text: 'Erkunde die Lage', link: '', scrollTo: false },
            }
        case Terraced_Wrapper.SthElse:
            return {
                head: {
                    title: ['Noch nicht das Richtige gefunden?'],
                },
                choices: [
                    {
                        content: 'Gucken Sie auch an der Villa Leuna vorbei.',
                        link: 'https://villa-leuna.de/',
                    },
                    {
                        content: 'Gucken Sie auch an der Villa Arbor vorbei.',
                        link: 'https://villa-arbor.de/',
                    },
                    {
                        content: 'Gucken Sie auch an der Villa Münster vorbei.',
                        link: 'https://villa-münster.de/',
                    },
                ],
            }
        case Terraced_Wrapper.Carousel:
            const _images = []
            const list = 11
            const skipNumbers = [1, 4, 6]

            _images.push({
                name: `img_14_bad`,
                folder: ['imgs', 'flats', 'flat_6'],
                alt: 'Alter Fährhof',
            })
            _images.push({
                name: `img_3_wohn`,
                folder: ['imgs', 'flats', 'flat_5'],
                alt: 'Alter Fährhof',
            })

            _images.push({
                name: `img_30_eltern`,
                folder: ['imgs', 'flats', 'flat_5'],
                alt: 'Alter Fährhof',
            })

            _images.push({
                name: `img_22_bad`,
                folder: ['imgs', 'flats', 'flat_1'],
                alt: 'Alter Fährhof',
            })

                        _images.push({
                            name: `main_18`,
                            folder: ['imgs', 'root'],
                            alt: 'Alter Fährhof',
                        })
            _images.push({
                name: `img_13_flur_og`,
                folder: ['imgs', 'flats', 'flat_1'],
                alt: 'Alter Fährhof',
            })
               _images.push({
                   name: `img_17_flur_og`,
                   folder: ['imgs', 'flats', 'flat_2'],
                   alt: 'Alter Fährhof',
               })
            for (let i = 1; i < list + 1; i++) {
               if (!skipNumbers.includes(i)) {
                    _images.push({
                        name: `terraced_${i}`,
                        folder: ['imgs', 'root'],
                        alt: 'Alter Fährhof',
                    })
                }
            }
            return {
                head: {
                    title: ['Bilder?'],
                },
                images: _images,
            }
        case Terraced_Wrapper.BottomSthElse:
            return {
                title: ['Weitere Wohnungen'],
                links: [
                    {
                        text: 'Erkunde das Haupthaus ',
                        link: '/wohnungen/haupthaus',
                        scrollTo: false,
                    },
                ],
                image: {
                    name: 'main_19',
                    folder: ['imgs', 'root'],
                    alt: 'Alter Fährhof von Oben',
                },
            }
        case Terraced_Wrapper.FlatList:
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _flats_2Floors: any[] = []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const _flats_3Floors: any[] = []
            {
                for (let j = 0; j < 7; j++) {
                    const data: Flat_Wrapper = flatData[j]
                    const newFlat: {
                        number: number
                        price: { main: number; partial: number }
                        size: number
                        available: boolean
                        rooms: { living: number; baths: number; sleep: number }
                        levels: { wLevels: Flat_Wrapper_Levels[]; nMain: number }
                        entrance: string
                        outSideExtra: string
                        blueprint: Image[]
                    } = {
                        number: data.flatNumber,
                        price: data.price,
                        size: data.size,
                        available: data.availability,
                        rooms: data.rooms,
                        levels: data.levels,
                        entrance: data.entrance,
                        outSideExtra: data.outSideExtra,
                        blueprint: data.blueprint,
                    }
                    if (newFlat.levels.nMain === 2) {
                        _flats_2Floors.push(newFlat)
                    } else {
                        _flats_3Floors.push(newFlat)
                    }
                }
            }

            return {
                content: [
                    'Wohneinheiten',
                    'in Reihenhäusern',
                    'Die Reihenhäuser umfassen insgesamt sieben Wohneinheiten mit vielfältigen Raumkonzepten und Grundrissen. ',
                    `Die Wohnungen 1 bis 4 erstrecken sich über zwei Etagen und beeindrucken mit großzügigen Flächen, die Ihnen viel Platz zum Leben und Entfalten bieten. 
                    Die Wohnungen 5 bis 7 erstrecken sich über drei Etagen.`,
                ],
                image: {
                    name: 'main_5',
                    folder: ['imgs', 'root'],
                    alt: 'Alter Fährhof von Oben',
                },
                mainLink: '/wohnungen/reihenhaus',
                type: 'reihenhaus',
                flats: [_flats_2Floors, _flats_3Floors],
            }
    }
}
