export enum Home_Wrapper {
    HeadInfo = 0,
    Descrb,
    DetailLinks,
    DetailSep,
    CenterTextSep,
    RouteMaps,
    Location,
}

export function getHomeInfoWrapped(data: Home_Wrapper) {
    switch (data) {
        case Home_Wrapper.HeadInfo:
            return {
                head: { title: 'Alter Fährhof' },
                content: [
                    { link: '/lage', title: 'Lage', displayState: false },
                    { link: '/geschichte', title: 'Geschichte', displayState: false },
                    { link: '/bauverlauf', title: 'Bauverlauf', displayState: false },
                    {
                        link: '/wohnungen/haupthaus',
                        title: 'Verfügbare Wohnungen',
                        displayState: false,
                    },
                ],
            }

        case Home_Wrapper.Location:
            return {
                head: {
                    title: ['Hochwertiges Wohnen direkt an der Saale'],
                    titleSlash: false,
                },
                image: {
                    name: 'main_19',
                    folder: ['imgs', 'root'],
                    // alt: 'Dguendel - https://commons.wikimedia.org/wiki/File:Bad_D%C3%BCrrenberg,_das_Gradierwerk-5.jpg',
                    alt: 'Der Alte Fährhof direkt an der Saale',
                    // source: {
                    //     text: 'Dguendel',
                    //     link: 'https://commons.wikimedia.org/wiki/File:Bad_D%C3%BCrrenberg,_das_Gradierwerk-5.jpg',
                    //     additional: {
                    //         text: 'CC BY 3.0',
                    //         link: 'https://creativecommons.org/licenses/by/3.0',
                    //     },
                    // },
                },
                content: [
                    'Exklusives Wohnen direkt an der Saale',
                    `Die Lage des Wohnortes bietet eine ideale Anbindung an die pulsierenden Städte Leipzig und Halle. ` +
                        `Tauchen Sie ein in die entspannte Atmosphäre der Solestadt Bad Dürrenberg und erkunden Sie den nahegelegenen Kurpark. ` +
                        `Erleben Sie die Ruhe und Schönheit am Saaleufer mit atemberaubendem Flussblick.`,
                ],
                links: [
                    {
                        text: 'Mehr Infos zur Lage',
                        link: '/lage',
                        scrollTo: false,
                    },
                ],
            }
        case Home_Wrapper.DetailLinks:
            return {
                head: { title: 'Finde mehr über den Alten Fährhof heraus' },
                content: [
                    {
                        link: '/wohnungen',
                        title: 'Mietwohnungen',
                        image: {
                            name: 'img_11_eltern',
                            folder: ['imgs', 'flats', 'flat_6'],
                            alt: 'Mietwohnungen',
                        },
                    },
                    {
                        link: '/bauverlauf',
                        title: 'Bauverlauf',
                        image: {
                            name: 'main_21',
                            folder: ['imgs', 'root'],
                            alt: 'Bauverlauf',
                        },
                    },
                ],
            }
        case Home_Wrapper.DetailSep:
            return {
                content: [
                    'Der Alte Fährhof - Ein Zuhause mit Charakter',
                    `In Bad Dürrenberg, direkt am Borlachturm, liegt der Alte Fährhof. Das historische Gebäudeensemble wurde mit viel Liebe zum Detail saniert und bietet 14 traumhafte Wohnungen. ` +
                        'Die Wohnungen sind ideal für alle, die ein modernes und nachhaltiges Zuhause suchen. ',
                    'Sie bieten ein Höchstmaß an Komfort und Wohnqualität in einer idyllischen Lage.',
                ],
                content_seperation: true,
                // link: {
                //     text: 'Erfahre mehr',
                //     link: 'imageLinks',
                //     scrollTo: true,
                // },
            }
        case Home_Wrapper.CenterTextSep:
            return {
                content: [
                    `In Exklusiver Lage und mit Blick auf Saale und den zentralen Innenhof des Alten Fährhofs, ` +
                        `bietet das Torbogenzimmer eine exklusive Wohnmöglichkeit für Alleinstehende und junge Erwachsene.`,
                ],
                link: {
                    text: 'Zum Torbogenzimmer',
                    link: '/wohnungen/torbogenzimmer',
                    scrollTo: false,
                },
            }
        case Home_Wrapper.RouteMaps:
            return {
                head: { title: 'Erleben Sie den Alten Fährhof vor Ort' },
                content: ['Sprechen Sie uns an und vereinbaren Sie noch heute einen persönlichen Termin. ', 'Wir freuen uns auf Ihren Besuch.'],
                address: ['Alter Fährhof', 'Fährstraße 9-23', '06231 Bad Dürrenberg'],
                contactLink: {
                    text: 'Kontaktieren Sie uns',
                    link: '/kontakt',
                    scrollTo: false,
                },
                mapsLink: {
                    text: 'Standort auf Google Maps',
                    link: 'https://goo.gl/maps/ZySnj1BPCxKPWV7c6',
                    scrollTo: false,
                },
            }
    }
}
