import { getAvailableProjects } from './flat/flat_gen_Functions'

export enum Floors_Wrapper {
    HeadInfo = 0,
    Info,
    List,
    ImageLinks,
    CenterTextSep,
    InfoTextSep,
    BottomImage,
    Sep,
    SepSec,
}

export function getFloorsInfoWrapped(data: Floors_Wrapper) {
    switch (data) {
        case Floors_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Mietwohnungen', 'im Alten Fährhof'],
                    titleSlash: true,
                    links: [
                        {
                            text: 'Wohnen direkt an der Saale',
                            to: '/wohnungen',
                            slash: false,
                        },
                    ],
                },
                image: {
                    name: 'img_13_flur_og',
                    folder: ['imgs', 'flats', 'flat_6'],
                    alt: 'Konzeptbild',
                },
                content: [
                    'Moderne Wohnungen direkt an der Saale',
                    `Willkommen im malerischen Fährhof. Mit viel Liebe zum Detail entstehen hier 14 hochwertig kernsanierte Wohnungen. 
                    Diese großzügig gestalteten Wohnungen bieten Ihnen ein entspanntes und hochertiges Wohnambiente in der bezaubernden Solestadt Bad Dürrenberg. 
                    Erleben Sie die einzigartige Möglichkeit, direkt am Ufer der Saale zu leben.`,
                ],
            }
        case Floors_Wrapper.CenterTextSep:
            return {
                content: [
                    `In Exklusiver Lage und mit Blick auf Saale und den zentralen Innenhof des Alten Fährhofs,` +
                        `bietet das Torbogenzimmer eine einzigartige Wohnmöglichkeit für Alleinstehende und junge Erwachsene.`,
                ],
                link: {
                    text: 'Zum Torbogenzimmer',
                    link: '/wohnungen/torbogenzimmer',
                    scrollTo: false,
                },
            }
        case Floors_Wrapper.InfoTextSep:
            return {
                head: { title: 'Überblick der Ausstattung' },
                content: [
                    {
                        title: 'Wohnen',
                        rows: ['Fußbodenheizung', 'Echtholzparkett', 'Hochwertige Innenausstattung'],
                    },
                    {
                        title: 'Energie',
                        rows: [
                            'Effiziente Luft-Wasser-Wärmepumpe',
                            'Holzfenster mit Dreifachverglasung als Wärme- und Schallschutz',
                            'Niedrige Energiekosten durch hohe Dämmung (KfW-Effizienzhaus 70) ',
                        ],
                    },
                    {
                        title: 'Stellplätze',
                        rows: ['Stellplätze für Autos in Garagen und Innenhof', 'Fahrradstellplätze', 'Öffentlicher Parkplatz an der Saale in Fußweite'],
                    },
                    {
                        title: 'Lage',
                        rows: [
                            'Direkt an der Saale, dem Kurpark und dem Saaleradweg',
                            'Verkehrsberuhigte Lage in dem historischen Zentrum Bad Dürrenbergs',
                            'Verkehrsgünstige Lage durch Anbindung an die A9 und A38',
                        ],
                    },
                ],
            }
        case Floors_Wrapper.BottomImage:
            return {
                image: {
                    name: 'main_6',
                    folder: ['imgs', 'root'],
                    alt: 'Torbogenzimmer',
                },
            }
        case Floors_Wrapper.ImageLinks:
            let flatAvailability = getAvailableProjects()
            console.log(flatAvailability)
            return {
                head: { title: 'Überblick der Mietwohnungen' },
                content: [
                    {
                        link: '/wohnungen/reihenhaus',
                        title: 'Reihenhäuser',
                        available: flatAvailability.includes('reihenhaus'),
                        image: {
                            name: 'terraced_10',
                            folder: ['imgs', 'root'],
                            alt: 'Mietwohnungen in Reihenhäuser',
                        },
                    },
                    {
                        link: '/wohnungen/haupthaus',
                        title: 'Haupthaus',
                        available: flatAvailability.includes('haupthaus'),
                        image: {
                            name: 'main_19',
                            folder: ['imgs', 'root'],
                            alt: 'Mietwohnungen im Haupthaus',
                        },
                    },
                ],
            }
    }
}
