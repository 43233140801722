import { Image } from '../../server'
const Flat_Wrapper_Image_Description = require('./flat_descriptions')
const cache = require('./cache')

export enum Flat_Wrapper_Levels {
    Erdgeschoss = 'Erdgeschoss',
    Obergeschoss = 'Obergeschoss',
    Dachgeschoss = 'Dachgeschoss',
}

export enum Flat_Wrapper_Outside_Perk {
    Terrace = 'Große Terrasse im Innenhof',
    Balcony = 'Großer Balkon im Innenhof',
    _Balcony = 'Große Terrasse mit Blick auf die Saale',
    None = '',
}

export enum Flat_Wrapper_Side {
    Street = 'Saale',
    Courtyard = 'Innenhof',
    Both = '',
}

export enum Flat_Wrapper_View {
    Courtyard = 'Innenhof',
    Saale = 'Saale',
    Street = 'Straße',
}

export enum Flat_Wrapper_Building {
    Main = 'haupthaus',
    Terraced = 'reihenhaus',
    Torbogen = 'torbogenzimmer',
}

export enum Flat_Wrapper_Entrance_Side {
    Street = '',
    Courtyard = 'Privater Hauseingang',
    Both = '',
}

export enum Flat_Page_Component_Wrapper {
    HeadInfo = 0,
    Details,
    Carousel,
    SthElse,
    Blueprint,
    Attributes,
}

export enum Flat_Wrapper_Heating {
    Central = 'Zentrale Luft-Wasser-Wärmepumpe',
    Own = 'Eigene Luft-Wasser-Wärmepumpe',
    Floor = 'Effiziente Fußbodenheizung',
}

export enum Flat_Wrapper_Venting {
    ERoll = 'Elektrische Rolladen',
    Roll = 'Rolladen',
}

export enum Flat_Wrapper_Baths {
    GuestB = 'Gäste-WC',
    MainB = 'Hauptbadezimmer',
}

export enum Flat_Wrapper_Garage {
    Garage = 'Garage mit fernsteuerbarem Sektionaltor',
    SpaceInside = 'Stellplatz im Innenhof',
    Power = 'Stromanschluss in der Garage',
}

export enum Flat_Wrapper_Bike {
    Bike = 'Fahrradstellplatz',
    Lock = 'Abschließbar',
}

export class Flat_Page_Wrapper {
    constructor(
        public flat: number,
        public segment: Flat_Wrapper_Building,
        public component: Flat_Page_Component_Wrapper
    ) {}
}

export class Flat_Wrapper {
    blueprint: Image[]
    images: { count: number; value: Image[]; head: Image; door?: Image }
    constructor(
        public flatNumber: number,
        public price: { main: number; partial: number },
        public availability: boolean,
        public size: number,
        public levels: { wLevels: Flat_Wrapper_Levels[]; nMain: number },
        public rooms: { living: number; baths: number; sleep: number },
        public outSideExtra: Flat_Wrapper_Outside_Perk,
        public side: Flat_Wrapper_Side,
        public segment: Flat_Wrapper_Building,
        public entrance: Flat_Wrapper_Entrance_Side,
        public imageArray: { count?: number; description: Array<string> },
        public doorImage: boolean,
        public headImage: { name: string; folder: string[] },
        public technical: {
            heating: Flat_Wrapper_Heating[]
            venting: Flat_Wrapper_Venting[]
            garage: Flat_Wrapper_Garage[]
            bike: Flat_Wrapper_Bike[]
        },
        public view: Flat_Wrapper_View[],
        public cDescrView: string[],
        public fHeadDescr: string,
        public colorScheme?: { r: number; g: number; b: number },
        public lineImageDescription?: boolean,
        public flatListInfo?: string
    ) {
        this.blueprint = this.getBlueprintImages()

        this.images = {
            count: imageArray.count ? imageArray.count : imageArray.description.length,
            value: this.getImages(imageArray.count ? imageArray.count : imageArray.description.length, imageArray.description),
            head: this.getHImage(headImage.folder, headImage.name),
            door: this.getDImage(doorImage),
        }

        if (imageArray.description.length === 0) {
            this.buildFlatImages().then((res) => {
                this.images = res
            })
        }
    }
    buildFlatImages(): Promise<{ count: number; value: Image[]; head: Image; door?: Image }> {
        return new Promise((resolve, reject) => {
            let count = 0
            let value: Image[] = []
            let head: Image = this.getHImage(this.headImage.folder, this.headImage.name)

            const flatImages = cache[`flat_${this.flatNumber}`]
            if (flatImages) {
                // Sort images by the number in their filename
                const sortedFlatImages = flatImages.sort((a: any, b: any) => {
                    const numberA = parseInt(a.split('_')[1])
                    const numberB = parseInt(b.split('_')[1])
                    return numberA - numberB
                })

                sortedFlatImages.forEach((img: string) => {
                    const imgParts = img.split('_')
                    if (imgParts[0] === 'img' && imgParts.length >= 3) {
                        const descriptionKey = imgParts
                            .slice(2)
                            .join('_')
                            .replace(/\.[^/.]+$/, '')
                        const description = Flat_Wrapper_Image_Description[descriptionKey] || 'No description'

                        value.push({
                            name: img,
                            description: description,
                            folder: ['imgs', 'flats', `flat_${this.flatNumber}`],
                            alt: `${description} Wohnung ${this.flatNumber}`,
                        })
                    }
                })
            }

            count = value.length
            resolve({ count, value, head })
        })
    }

    getBlueprintImages(): Image[] {
        let _images: Image[] = []
        switch (this.segment) {
            case 'haupthaus':
                _images = [
                    {
                        name: 'blueprint_1',
                        description: 'Grundriss',
                        folder: ['imgs', 'flats', 'flat_' + this.flatNumber],
                        alt: 'Grundriss Wohnung ' + this.flatNumber,
                    },
                ]
                return _images
            default:
                if (this.segment !== 'reihenhaus') return []
                if (this.flatNumber < 5) {
                    _images = [
                        {
                            name: 'blueprint_1',
                            description: 'Erdgeschoss',
                            folder: ['imgs', 'flats', 'flat_' + this.flatNumber],
                            alt: 'Erdgeschoss Wohnung ' + this.flatNumber,
                        },
                        {
                            name: 'blueprint_2',
                            description: 'Obergeschoss',
                            folder: ['imgs', 'flats', 'flat_' + this.flatNumber],
                            alt: 'Obergeschoss Wohnung ' + this.flatNumber,
                        },
                    ]
                    return _images
                } else {
                    _images = [
                        {
                            name: 'blueprint_1',
                            description: 'Erdgeschoss',

                            folder: ['imgs', 'flats', 'flat_' + this.flatNumber],
                            alt: 'Erdgeschoss Wohnung ' + this.flatNumber,
                        },
                        {
                            name: 'blueprint_2',
                            description: 'Obergeschoss',

                            folder: ['imgs', 'flats', 'flat_' + this.flatNumber],
                            alt: 'Obergeschoss Wohnung ' + this.flatNumber,
                        },
                        {
                            name: 'blueprint_3',
                            description: 'Dachgeschoss',

                            folder: ['imgs', 'flats', 'flat_' + this.flatNumber],
                            alt: 'Obergeschoss Wohnung ' + this.flatNumber,
                        },
                    ]
                    return _images
                }
        }
    }

    getImages(imageCount: number, descriptionArray: Array<string>): Image[] {
        const _images = []
        if (this.doorImage) {
            _images.push(this.getDImage(this.doorImage) as Image)
        }
        for (let i = 1; i < imageCount + 1; i++) {
            let description
            try {
                description = descriptionArray[i - 1]
            } catch (e) {
                /// TODO: ERROR HANDLING
            }
            _images.push({
                name: `img_${i}`,
                description: description,
                folder: ['imgs', 'flats', `flat_${this.flatNumber}`],
                alt: `Bild Wohnung ${this.flatNumber}`,
            })
        }
        return _images
    }

    getHImage(folder: string[], name: string): Image {
        const image = {
            name: name,
            description: `flat_head_${this.flatNumber}`,
            folder: folder,
            alt: `Bildkopf Wohnung ${this.flatNumber}`,
        }
        return image
    }

    getDImage(isDoor: boolean): Image | undefined {
        if (!isDoor) return undefined
        const image = {
            name: 'img_door',
            description: `Wohneingangstür`,
            folder: ['imgs', 'flats', 'flat_' + this.flatNumber],
            alt: `Eingangstür Wohnung ${this.flatNumber}`,
        }
        return image
    }

    getNumberasString(a: number, uppercase: boolean): string {
        switch (a) {
            case 1:
                return uppercase ? 'Ein' : 'ein'
            case 2:
                return uppercase ? 'Zwei' : 'zwei'
            case 3:
                return uppercase ? 'Drei' : 'drei'
            case 4:
                return uppercase ? 'Vier' : 'vier'
            case 5:
                return uppercase ? 'Fünf' : 'fünf'
            case 6:
                return uppercase ? 'Sechs' : 'sechs'
            default:
                return a.toString()
        }
    }
}
