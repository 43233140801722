/* eslint-disable no-unsafe-optional-chaining */
import { flatData } from './flats_data'
import { Flat_Page_Component_Wrapper, Flat_Wrapper, Flat_Page_Wrapper, Flat_Wrapper_View, Flat_Wrapper_Outside_Perk } from './flat_class_Wrapped'
import { getNextAvailableFlat } from './flat_gen_Functions'

function getSegmentNavigation(segment: string) {
    switch (segment) {
        case 'haupthaus':
            return {
                text: 'Haupthaus',
                to: '/wohnungen/haupthaus', ///#wohnungen
                slash: true,
            }
        case 'reihenhaus':
            return {
                text: 'Reihenhäuser',
                to: '/wohnungen/reihenhaus', ///#wohnungen
                slash: true,
            }
    }
}

function getContentText(segment: string, flat: Flat_Wrapper) {
    let _content_1 = ''
    const _content_2 = ['']

    if (flat.flatNumber < 8) {
        _content_1 = 'Details zum Reihenhaus'
        _content_2.push(flat.fHeadDescr)
        if (flat.flatNumber < 5) {
            _content_2.push(
                `Über ${flat.getNumberasString(flat.levels.nMain, false)} Etagen aufgeteilt, umfasst die Wohnung ${flat.size} m² Wohnfläche. ` +
                    `${flat.getNumberasString(flat.rooms.living, true)} großzügige Wohnräume bieten Ihnen vielfältige Möglichkeiten zur individuellen Gestaltung und persönlichen Entfaltung. ` +
                    `Die Wohnung besticht durch eine hochwertige und ansprechende Ausstattung, die für ein stilvolles Wohnambiente sorgt. 
          Die traumhafte Lage ermöglicht entspannte Spaziergänge ` +
                    `entlang der malerischen Saale und durch den nahegelegenen Kurpark. Tauchen Sie ein in die Ruhe und Schönheit der Solestadt und genießen Sie einen außergewöhnlichen Lebensstil, ` +
                    `der Natur, Erholung und Genuss harmonisch verbindet.`
            )
        } else {
            _content_2.push(
                `Das Reihenhaus ist mit ${flat.size} m² Wohnfläche über ${flat.getNumberasString(flat.levels.nMain, false)} Etagen aufgeteilt.  ${flat.getNumberasString(
                    flat.rooms.living,
                    true
                )} geräumige Wohnräume, hochwertige Ausstattung und ${flat.getNumberasString(flat.rooms.baths, false)} moderne${
                    flat.rooms.baths === 1 ? 's' : ''
                } Badezimmer sorgen für einen hohen Wohnkomfort. Genießen Sie die traumhafte Lage inmitten der malerischen Umgebung der Saale und des nahegelegenen Kurparks.`
            )
        }
    } else {
        _content_1 = 'Informationen zur Wohnung'

        _content_2.push(
            `Die Wohnung ${flat.flatNumber} befindet sich im ${flat.levels.wLevels} des Haupthauses und verfügt über ${
                flat.size
            } m² Wohnfläche. Großzügige Wohnräume, hochwertige und exklusive Ausstattung und ${flat.getNumberasString(flat.rooms.baths, false)} moderne${
                flat.rooms.baths === 1 ? 's' : ''
            } Badezimmer, sorgen für hohen Wohnkomfort.`
        )
        if (flat.view.includes(Flat_Wrapper_View.Saale)) {
            _content_2.push(`Genießen Sie von Ihrer Wohnung aus den direkten Blick auf die malerische Saale.`)
        }
        if (flat.outSideExtra.includes(Flat_Wrapper_Outside_Perk._Balcony)) {
            _content_2.push(`Genießen Sie atemberaubende Sonnenuntergänge von Ihrer malerischen Dachterrasse aus und lassen Sie den Tag in wunderschönen Ambiente ausklingen.`)
        } else {
            _content_2.push(`
            Tauchen Sie ein in die traumhafte Lage dieser Wohnung, umgeben von der malerischen Schönheit der Saale und des nahegelegenen Kurparks. `)
        }
        if (flat.view.includes(Flat_Wrapper_View.Saale)) {
            _content_2.push('Hier erwartet Sie ein außergewöhnlicher Lebensstil, der die perfekte Harmonie von Natur, Erholung und Genuss vereint.')
        } else if (flat.view.includes(Flat_Wrapper_View.Courtyard)) {
            _content_2.push(`Hier erwartet Sie ein außergewöhnlicher Lebensstil, in dem die Schönheit der Natur, Entspannung und Genuss in perfekter Harmonie miteinander verschmelzen.`)
        }
    }

    //_content_2 ="Sie erwartet eine großzügig geschnittene Wohnung für die ganze Familie. Helle Wohnräume und einen wundervollen Platz zu leben.";

    return [_content_1, _content_2.join(' ')]
}

function getAttributes(flat: Flat_Wrapper) {
    const _head = {
        title: 'Details',
        subTitle: 'Wohnung ' + flat.flatNumber + ' - Ausstattung',
    }
    const _flat = [
        String(
            flat.levels.wLevels.length > 1
                ? '<strong>' + flat.size + ' m²</strong> Wohnfläche auf ' + flat.levels.nMain + ' Etagen'
                : '<strong>' + flat.size + ' m²</strong> Wohnfläche im ' + flat.levels.wLevels[0]
        ),
        flat.getNumberasString(flat.rooms.living, true) + ' Wohnräume',

        String('Echtholzparkett ' + (flat.levels.nMain === 2 ? 'in dem Obergeschoss' : flat.levels.nMain === 3 ? 'in den Obergeschossen' : 'in dem Schlafzimmer')),
        String(
            flat.rooms.baths > 1
                ? 'Großes und modernes Badezimmer mit bodentiefer Dusche und hochwertigen Armaturen & Gäste-WC'
                : 'Großes und modernes Badezimmer mit bodentiefer Dusche und hochwertigen Armaturen'
        ),
        flat.entrance !== '' && flat.entrance,
        flat.outSideExtra.length > 1 && flat?.outSideExtra,
        String('Niedrige Energiekosten durch hohe Dämmung <strong>(KfW-Effizienzhaus 70)</strong>'),
        ...flat?.technical.heating,
        ...flat?.technical.venting,
        String('Fahrradstellplatz'),
        String('Dazu mietbare Garage mit fernsteuerbarem Sektionaltor / Stellplatz'),
    ]
    return {
        content: {
            head: _head,
            flat: _flat,
            colorScheme: flat.colorScheme,
            rent: 0,
            // rent: flat.price.main,
            availability: flat.availability,
            link: {
                to: '/kontakt?wohnung=' + flat.flatNumber,
            },
        },
    }
}

function buildHead(flat: Flat_Wrapper, segment: string) {
    return {
        title: ['Wohnung ' + flat.flatNumber],
        titleSlash: true,
        flatFont: true,
        subTitle: [flat.availability ? 'Verfügbar' : 'Schon vermietet'],
        subTitleC: [flat.size + ' m²'],
        forceSubTitlte: true,
        colorScheme: flat.colorScheme,
        links: [
            {
                text: 'Mietwohnungen',
                to: '/wohnungen',
                slash: false,
                slashOverride: true,
            },
            getSegmentNavigation(segment),
        ],
    }
}

function getHeadInfo(flat: Flat_Wrapper, segment: string) {
    if (flat) {
        return {
            head: buildHead(flat, segment),
            image: buildHeadImage(flat),
            content: getContentText(segment, flat),
        }
    }
}

function buildHeadImage(flat: Flat_Wrapper) {
    return flat.images.head
}

function getCarousel(flat: Flat_Wrapper) {
    if (flat.availability) {
        return {
            flat: flat.flatNumber,
            images: flat.images.value,
        }
    } else {
        return {
            flat: flat.flatNumber,
            images: [],
        }
    }
}

function getSthElse(flat: number, segment: string) {
    const nextFreeFlat = getNextAvailableFlat(flat)

    if (nextFreeFlat === undefined || nextFreeFlat === -1) {
        return getUnavailableFlatInfo()
    }

    switch (flat) {
        case 1:
            return getFlatInfo(nextFreeFlat, segment)
        case 7:
            return getNextFlatInfo(flat + 1, 'Haupthaus')
        case 13:
            return getSpecificFlatInfo()
        default:
            if ((flat > 1 && flat < 7) || (flat > 7 && flat < 13)) {
                return getFlatInfo(nextFreeFlat, segment, flat - 1, flat <= 8 ? 'reihenhaus' : 'haupthaus')
            }
    }
}

function getFlatInfo(nextFreeFlat: number, segment: string, lastFlat?: number, lastSegment?: string) {
    const { segmentUpper, name } = determineSegmentAndName(nextFreeFlat)
    const links = [
        {
            text: nextFreeFlat === 14 ? 'Zum Torbogenzimmer' : `Nächste freie Wohnung ${nextFreeFlat}`,
            link: `/wohnungen/${segment}/${nextFreeFlat}`,
            scrollTo: false,
        },
    ]

    if (lastFlat !== undefined && lastSegment !== undefined) {
        links.push({
            text: 'Vorherige Wohnung',
            link: `/wohnungen/${lastSegment}/${lastFlat}`,
            scrollTo: false,
        })
    }

    return buildReturnStructure(segmentUpper, name, links)
}

function getNextFlatInfo(newFlat: number, segment: string) {
    return {
        title: [`Erkunden Sie das Weitere Angebot im ${segment}`],
        links: [
            {
                text: `Nächste Wohnung ${newFlat}`,
                link: `/wohnungen/haupthaus/${newFlat}`,
                scrollTo: false,
            },
            {
                text: `Informationen zum ${segment}`,
                link: `/wohnungen/haupthaus`,
                scrollTo: false,
            },
        ],
        image: {
            name: 'main_18',
            folder: ['imgs', 'root'],
            alt: `Präsentation des ${segment}`,
        },
    }
}

function getSpecificFlatInfo() {
    return {
        title: ['Erkunden Sie das Weitere Angebot'],
        links: [
            {
                text: 'Zum Torbogenzimmer',
                link: '/wohnungen/torbogenzimmer',
                scrollTo: false,
            },
            {
                text: 'Zu den Reihenhäusern',
                link: '/wohnungen/reihenhaus',
                scrollTo: false,
            },
        ],
        image: {
            name: 'terraced_6',
            folder: ['imgs', 'root'],
            alt: 'Präsentation Nächste Wohnung 13',
        },
    }
}

function getUnavailableFlatInfo() {
    return {
        title: ['Erkunden Sie den Alten Fährhof'],
        links: [
            {
                text: 'Informationen zu den Reihenhäusern',
                link: '/wohnungen/reihenhaus',
                scrollTo: false,
            },
            {
                text: 'Informationen zum Haupthaus',
                link: '/wohnungen/haupthaus',
                scrollTo: false,
            },
        ],
        image: {
            name: 'main_18',
            folder: ['imgs', 'root'],
            alt: 'Präsentation des Haupthauses',
        },
    }
}

function buildReturnStructure(segmentUpper: any, name: any, links: any) {
    return {
        title: ['Weitere ' + segmentUpper],
        links: links,
        image: {
            name: name,
            folder: ['imgs', 'root'],
            alt: 'Präsentation Nächste Wohnung ' + segmentUpper,
        },
    }
}

function determineSegmentAndName(flatNumber: number) {
    if (flatNumber > 0 && flatNumber < 4) {
        return { segmentUpper: 'Reihenhäuser', name: 'terraced_2' }
    } else if (flatNumber < 8) {
        return { segmentUpper: 'Reihenhäuser', name: 'terraced_9' }
    } else {
        return { segmentUpper: 'Wohnungen im Haupthaus', name: 'main_3' }
    }
}

function getBlueprint(flat: Flat_Wrapper, segment: string) {
    const bpText = getBlueprintText(flat)
    const bpImages = getBlueprintImages(flat.flatNumber, segment)
    const data = {
        head: bpText.head,
        content: bpText.content,
        colorScheme: flat.colorScheme,
        button: bpText.button,
        levels: bpText.levels,
        images: bpImages?.images,
    }
    return data
}

function getBlueprintText(flat: Flat_Wrapper) {
    const levels = flat.levels.wLevels
    const _content = new Map<number, string[]>()
    _content.set(0, [])
    _content.set(1, [])

    let _levels = []
    let _head = ''
    let _button = undefined
    if (flat.levels.nMain === 2) {
        _head = 'Beschreibung der Etagen'

        _content.get(0)?.push(' ' + flat.cDescrView[0])
        _content.get(1)?.push(' ' + flat.cDescrView[1])

        _button = []
        _button.push({ lead: 'Zum ', text: `${levels[0]}`, level: 1 })
        _button.push({ lead: 'Zum ', text: `${levels[1]}`, level: 2 })
    }
    if (flat.levels.nMain === 3) {
        _head = 'Beschreibung der Etagen'
        _content.set(2, [])

        _content.get(0)?.push(' ' + flat.cDescrView[0])
        _content.get(1)?.push(' ' + flat.cDescrView[1])
        _content.get(2)?.push(' ' + flat.cDescrView[2])

        _button = []
        _button.push({
            lead: 'Zum ',
            text: `${levels[0]}`,
            level: 1,
        })
        _button.push({
            lead: 'Zum ',
            text: `${levels[1]}`,
            level: 2,
        })
        _button.push({
            lead: 'Zum ',
            text: `${levels[2]}`,
            level: 3,
        })
    }
    if (flat.levels.nMain === 1) {
        _head = 'Beschreibung der Wohnung'

        _content.get(0)?.push(' ' + flat.cDescrView[0])
    }

    _levels = flat.levels.wLevels

    return {
        head: {
            title: _head,
            flat: flat.flatNumber,
        },
        content: _content,
        levels: _levels,
        button: _button,
    }
}

function getBlueprintImages(flat: number, segment: string) {
    switch (segment) {
        case 'haupthaus':
            return {
                images: [
                    {
                        name: 'blueprint_1',
                        folder: ['imgs', 'flats', 'flat_' + flat],
                        alt: 'Grundriss Wohnung ' + flat,
                    },
                ],
            }
        case 'reihenhaus':
            if (flat < 5) {
                return {
                    images: [
                        {
                            description: 'Erdgeschoss',
                            name: 'blueprint_1',
                            folder: ['imgs', 'flats', 'flat_' + flat],
                            alt: 'Erdgeschoss Wohnung ' + flat,
                        },
                        {
                            description: 'Dachgeschoss',
                            name: 'blueprint_2',
                            folder: ['imgs', 'flats', 'flat_' + flat],
                            alt: 'Dachgeschoss Wohnung ' + flat,
                        },
                    ],
                }
            } else {
                return {
                    images: [
                        {
                            name: 'blueprint_1',
                            description: 'Erdgeschoss',
                            folder: ['imgs', 'flats', 'flat_' + flat],
                            alt: 'Erdgeschoss Wohnung ' + flat,
                        },
                        {
                            name: 'blueprint_2',
                            description: 'Obergeschoss',
                            folder: ['imgs', 'flats', 'flat_' + flat],
                            alt: 'Obergeschoss Wohnung ' + flat,
                        },
                        {
                            name: 'blueprint_3',
                            description: 'Dachgeschoss',
                            folder: ['imgs', 'flats', 'flat_' + flat],
                            alt: 'Obergeschoss Wohnung ' + flat,
                        },
                    ],
                }
            }
    }
}

export function getFlatInfoWrapped(data: Flat_Page_Wrapper) {
    const flat = flatData.filter((item) => item.flatNumber === data.flat)[0]
    if (flat === undefined) return {}
    if ((data.flat > 0 && data.flat <= 7 && data.segment === 'reihenhaus') || (data.flat > 7 && data.flat < 14 && data.segment === 'haupthaus')) {
        switch (data.component) {
            case Flat_Page_Component_Wrapper.HeadInfo:
                return getHeadInfo(flat, data.segment)
            case Flat_Page_Component_Wrapper.Carousel:
                return getCarousel(flat)
            case Flat_Page_Component_Wrapper.SthElse:
                return getSthElse(flat.flatNumber, data.segment)
            case Flat_Page_Component_Wrapper.Attributes:
                return getAttributes(flat)
            case Flat_Page_Component_Wrapper.Blueprint:
                return getBlueprint(flat, data.segment)
        }
    }
}
