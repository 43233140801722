import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { splitPathname } from '../helpers/handlePathname'
import { useScrollBlock } from '../helpers/scrollHandler'
import React from 'react'

export default function NavigateTo(props) {
    const [route, setRoute] = useState(false)
    const { pathname } = useLocation()
    const [lastPath, setLastPath] = useState(false)
    
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const [blockScroll, allowScroll] = useScrollBlock()
    useEffect(() => {
        allowScroll()
        const path = splitPathname(pathname)
        if (path === lastPath) return
        setLastPath(path)
        const reihenhaus = [1, 2, 3, 4, 5, 6, 7]
        const haupthaus = [8, 9, 10, 11, 12, 13]
        const torbogen = [14]
        if ((path.segment === 'reihenhaus' || path.segment === 'haupthaus') && path.number === 0) {
            setRoute('invalid')
        } else
        if (reihenhaus.includes(path.number) || haupthaus.includes(path.number) || torbogen.includes(path.number) || path.number === -1) {
   
            if (path.segment === 'wohnungen' && path.number !== -1) {
                if (reihenhaus.includes(path.number)) {
                    setRoute('/wohnungen/reihenhaus/' + path.number)
                } else if (haupthaus.includes(path.number)) {
                    setRoute('/wohnungen/haupthaus/' + path.number)
                } else if (torbogen.includes(path.number)) {
                    setRoute('/wohnungen/torbogenzimmer')
                } else {
                    setRoute(false)
                }
            } else if (path.segment === 'uebersicht' || path.segment === '%C3%BCbersicht') {
                if (path.segment === 'uebersicht' && path.number !== -1) {
                    setRoute('/wohnungen/uebersicht')
                } else if (path.segment === '%C3%BCbersicht') {
                    setRoute('/wohnungen/uebersicht')
                } else {
                    setRoute(false)
                }
            } else if (path.number !== -1) {
                if ((path.segment === 'reihenhaus' || path.segment === 'reihenh%C3%A4user') && (path.number < 0 || path.number > 7)) {
                    setRoute('/wohnungen/haupthaus/' + path.number)
                } else if (path.segment === 'haupthaus' && path.number < 8) {
                    setRoute('/wohnungen/reihenhaus/' + path.number)
                } else if (path.number === 14) {
                    setRoute('/wohnungen/torbogenzimmer')
                } else if (path.segment === 'reihenh%C3%A4user') {
                    if (path.number > 0 && path.number <= 7) {
                        setRoute('/wohnungen/reihenhaus/' + path.number)
                    }
                } else {
                    setRoute(false)
                }
            } else if (path.segment === 'reihenh%C3%A4user') {
                setRoute('/wohnungen/reihenhaus')
            } else {
                setRoute(false)
            }
        } else {
            setRoute('invalid')
        }
    }, [props, pathname])
    if (route === 'invalid') {
        return <>{<Navigate to={'/'} />}</>
    } else {
        return <>{route !== false && <Navigate to={route} />}</>
    }
}
