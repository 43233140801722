import { Project } from '../cloudinaryHelper'

export enum OtherProjects_Wrapper {
    HeadInfo = 0,
    Descrb,
    BottomText,
    BottomImage,
    CenterImage,
}

export function getOtherProjectsInfoWrapped(data: OtherProjects_Wrapper) {
    switch (data) {
        case OtherProjects_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Weitere Projekte'],
                    titleSlash: true,
                    links: [
                        {
                            text: 'Wir sind mehr als nur der Alte Fährhof',
                            to: '/projekte',
                            slash: false,
                        },
                    ],
                },
                images: [
                    {
                        description: '',
                        name: 'img_f_johanna',
                        folder: ['imgs'],
                        alt: 'Villa Leuna - 1',
                        project: Project.villaLeuna,
                    },
                    {
                        description: '',
                        name: 'img_1',
                        folder: ['imgs'],
                        alt: 'Villa Leuna',
                        project: Project.villaLeuna,
                    },
                    {
                        description: '',
                        name: 'img_og',
                        folder: ['imgs'],
                        alt: 'Obergeschoss Flur',
                        project: Project.villaLeuna,
                    },
                    {
                        description: '',
                        name: 'img_garagen',
                        folder: ['imgs'],
                        alt: 'Garagen',
                        project: Project.villaLeuna,
                    },
                    {
                        description: '',
                        name: 'img_eg',
                        folder: ['imgs'],
                        alt: 'Erdgeschoss Flur',
                        project: Project.villaLeuna,
                    },
                    {
                        description: '',
                        name: 'img_8',
                        folder: ['imgs', 'johanna'],
                        alt: 'Winter Villa Johanna',
                        project: Project.villaLeuna,
                    },
                ],
                content: [
                    'Willkommen in der Villa Leuna',

                    `Nur einen Katzensprung von Bad Dürrenberg entfernt erwartet Sie die Villa Leuna, ein moderner Wohnkomplex mit insgesamt 12 exklusiven Neubauwohnungen. 
                    Die neugebauten Wohnungen bieten eine Wohnfläche zwischen 89 m² und 101 m². Lassen Sie sich von unserem stilvollen Ambiente und der hochwertigen Ausstattung begeistern.`,
                ],
                links: [
                    {
                        text: 'Erkunde die Villa Leuna',
                        link: 'https://www.villa-leuna.de/',
                        target: true,
                        scrollTo: false,
                    },
                ],
            }

        case OtherProjects_Wrapper.Descrb:
            return {
                images: [
                    {
                        description: '',
                        name: 'new',
                        folder: ['imgs'],
                        alt: 'Villa Arbor - 1',
                        project: Project.villaArbor,
                    },

                    {
                        description: '',
                        name: 'wohnzimmer',
                        folder: ['imgs'],
                        alt: 'Dachgeschoss',
                        project: Project.villaArbor,
                    },
                    {
                        description: '',
                        name: 'bath_1',
                        folder: ['imgs'],
                        alt: 'Villa Arbor - Bad',
                        project: Project.villaArbor,
                    },
                    {
                        description: '',
                        name: 'bath_2',
                        folder: ['imgs'],
                        alt: 'Villa Arbor - Bad',
                        project: Project.villaArbor,
                    },
                    {
                        description: '',
                        name: 'flur',
                        folder: ['imgs'],
                        alt: 'Dachgeschoss',
                        project: Project.villaArbor,
                    },
                ],
                content: [
                    'Erkunde die Villa Arbor',

                    `Erleben Sie exklusives Wohnen in einem hochwertig sanierten Wohngebäude inmitten einer ruhigen Wohngegend in Leuna. 
                    Die Villa Arbor bietet großzügige und helle Wohnräume, die Ihren individuellen Bedürfnissen gerecht werden.
                    Die Wohnungen erstrecken sich über eine Fläche von 64 m² bis 130 m² und bieten somit ausreichend Platz für ein komfortables und hochwertiges Wohngefühl. 
                    Hier finden Sie den perfekten Ort, um zur Ruhe zu kommen und Ihren Alltag hinter sich zu lassen. 
                    Entspannen Sie auf Ihrem Balkon oder in Ihrem großzügigen Wohnzimmer und genießen Sie die angenehme Atmosphäre, die von diesem außergewöhnlichen Wohnprojekt ausgeht.`,
                ],
                links: [
                    {
                        text: 'Zur Villa Arbor',
                        link: 'https://www.villa-arbor.de/',
                        target: true,
                        scrollTo: false,
                    },
                ],
            }

        case OtherProjects_Wrapper.BottomText:
            return {
                content: ['XXXXXX'],
            }
        case OtherProjects_Wrapper.CenterImage:
            return {
                image: {
                    name: 'img_water',
                    folder: ['imgs'],
                    alt: 'Saale - 1',
                    project: Project.villaLeuna,
                },
            }
        case OtherProjects_Wrapper.BottomImage:
            return {
                image: {
                    name: 'img_water_2',
                    folder: ['imgs'],
                    alt: 'Saale - 2',
                    project: Project.villaLeuna,
                },
            }
    }
}
