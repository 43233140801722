

import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

export const TabSEO = ({ children }) => {
    const location = useLocation()

    useEffect(() => {
        const empty = "Alter Fährhof";
        let title = empty
        switch (location.pathname) {
            case '/':
                title = empty
                break
            case '/about':
                title += ' - Kontakt'
                break
            case `/kontakt`:
                title += ' - Kontakt'
                break
            case '/impressum':
                title += ' - Impressum'
                break
            case '/datenschutz':
                title += ' - Datenschutz'
                break
            case `/lage`:
                title += ' - Lage'
                break
            case `/geschichte`:
                title += ' - Geschichte'
                break
            case `/bauverlauf`:
                title += ' - Bauverlauf'
                break
            case `/projekte`:
                title += ' - Weitere Projekte'
                break
            case `/wohnungen`:
                title += ' - Mietwohnungen'
                break
            case location.pathname.match('/wohnungen/reihenhaus/[0/-7]')?.input:
                title += ' - Reihenhaus ' + location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
                break
            case location.pathname.match('/wohnungen/haupthaus/[8/-13]')?.input:
                title += ' - Haupthaus ' + location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
                break
            case `/wohnungen/reihenhaus`:
                title += ' - Reihenhäuser'
                break
            case `/wohnungen/haupthaus`:
                title += ' - Haupthaus'
                break
            case `/wohnungen/torbogenzimmer`:
                title += ' - Torbogenzimmer'
                break

            default:
                title = empty
        }

        document.title = title;


    }, [location.pathname])
    return children
}
