export const flat_1 = [
  "blueprint_1.png",
  "blueprint_2.png",
  "img_10_wc.HEIC",
  "img_11_treppe.HEIC",
  "img_12_flur_og.HEIC",
  "img_13_flur_og.HEIC",
  "img_14_kind.HEIC",
  "img_15_kind.HEIC",
  "img_16_kind.HEIC",
  "img_17_kind.HEIC",
  "img_18_eltern.HEIC",
  "img_19_kind.HEIC",
  "img_1_door.HEIC",
  "img_20_bad.HEIC",
  "img_21_bad.HEIC",
  "img_22_bad.HEIC",
  "img_23_bad.HEIC",
  "img_24_bad.HEIC",
  "img_25_bad.HEIC",
  "img_26_bad.HEIC",
  "img_27_bad.HEIC",
  "img_28_bad.HEIC",
  "img_2_eingang.HEIC",
  "img_3_wohn.HEIC",
  "img_4_wohn.HEIC",
  "img_5_wohn.HEIC",
  "img_6_kueche.HEIC",
  "img_7_kueche.HEIC",
  "img_8_abstell.HEIC",
  "img_9_wc.HEIC"
];

export const flat_10 = [
  "blueprint_1.png",
  "img_10_bad.jpeg",
  "img_11_bad.jpeg",
  "img_1_flur.HEIC",
  "img_2_eltern.HEIC",
  "img_3_eltern.HEIC",
  "img_4_wohn.HEIC",
  "img_5_kueche.HEIC",
  "img_6_wohn.HEIC",
  "img_7_abstell.HEIC",
  "img_8_bad.jpeg",
  "img_9_bad.jpeg"
];

export const flat_11 = [
  "blueprint_1.png",
  "img_10_wohn.HEIC",
  "img_11_wohn.HEIC",
  "img_12_kueche.HEIC",
  "img_13_wohn.HEIC",
  "img_14_wohn.HEIC",
  "img_15_wohn.HEIC",
  "img_16.HEIC",
  "img_17_ausblick.HEIC",
  "img_18_balkon.HEIC",
  "img_19_balkon.HEIC",
  "img_1_door.HEIC",
  "img_20_balkon.HEIC",
  "img_21_balkon.HEIC",
  "img_2_flur.HEIC",
  "img_3_eltern.HEIC",
  "img_4_eltern.HEIC",
  "img_5_eltern.HEIC",
  "img_6_elternBG.HEIC",
  "img_7_elternBG.HEIC",
  "img_8_bad.jpeg",
  "img_9_bad.HEIC"
];

export const flat_12 = [
  "blueprint_1.png",
  "img_10_bad.jpg",
  "img_11_ausblick.HEIC",
  "img_12_wohn.HEIC",
  "img_13_kueche.HEIC",
  "img_14_wohn.HEIC",
  "img_15_ausblick.HEIC",
  "img_16_ausblick.HEIC",
  "img_1_flur.HEIC",
  "img_2_eltern.HEIC",
  "img_3_abstell.HEIC",
  "img_4_flur.HEIC",
  "img_5_bad.HEIC",
  "img_6_bad.HEIC",
  "img_7_bad.HEIC",
  "img_8_bad.HEIC",
  "img_9_bad.HEIC"
];

export const flat_13 = [
  "blueprint_1.png",
  "placeholderView.jpg"
];

export const flat_2 = [
  "blueprint_1.png",
  "blueprint_2.png",
  "img_10_wc.HEIC",
  "img_11_wc.HEIC",
  "img_12_wc.HEIC",
  "img_13_treppe.HEIC",
  "img_14_treppe.HEIC",
  "img_15_flur_og.HEIC",
  "img_16_flur_og.HEIC",
  "img_17_flur_og.HEIC",
  "img_18_kind.HEIC",
  "img_19_kind.HEIC",
  "img_1_door.HEIC",
  "img_20_eltern.HEIC",
  "img_21_eltern.HEIC",
  "img_22_eltern.HEIC",
  "img_23_eltern.HEIC",
  "img_24_bad.HEIC",
  "img_25_bad.HEIC",
  "img_26_bad.HEIC",
  "img_27_bad.HEIC",
  "img_28_bad.HEIC",
  "img_29_bad.HEIC",
  "img_2_door.HEIC",
  "img_30_bad.HEIC",
  "img_31_bad.HEIC",
  "img_32_bad.HEIC",
  "img_3_flur_eg.HEIC",
  "img_4_flur_eg.HEIC",
  "img_5_wohn.HEIC",
  "img_6_wohn.HEIC",
  "img_7_kueche.HEIC",
  "img_8_kueche.HEIC",
  "img_9_abstell.HEIC"
];

export const flat_3 = [
  "blueprint_1.png",
  "blueprint_2.png",
  "img_1.jpg",
  "img_10.jpg",
  "img_11.jpg",
  "img_2.jpg",
  "img_3.jpg",
  "img_4.jpg",
  "img_5.jpg",
  "img_6.jpg",
  "img_7.jpg",
  "img_8.jpg",
  "img_9.jpg",
  "img_door.jpg"
];

export const flat_4 = [
  "blueprint_1.png",
  "blueprint_2.png",
  "img_10_flur_og.HEIC",
  "img_11_bad.HEIC",
  "img_12_bad.HEIC",
  "img_13_bad.HEIC",
  "img_14_bad.HEIC",
  "img_15_bad.HEIC",
  "img_16_bad.HEIC",
  "img_17_eltern.HEIC",
  "img_18_eltern.HEIC",
  "img_19_eltern.HEIC",
  "img_1_door.HEIC",
  "img_20_eltern.HEIC",
  "img_21_abstell_technik.HEIC",
  "img_2_door.HEIC",
  "img_3_wohn.HEIC",
  "img_4_eingang.HEIC",
  "img_5_wc.HEIC",
  "img_6_kueche.HEIC",
  "img_7_kueche.HEIC",
  "img_8_treppe.HEIC",
  "img_9_treppe.HEIC"
];

export const flat_5 = [
  "blueprint_1.png",
  "blueprint_2.png",
  "blueprint_3.png",
  "img_10_ausblick.HEIC",
  "img_11_abstell_technik.HEIC",
  "img_12_ausblick.HEIC",
  "img_13_treppe.HEIC",
  "img_14_flur_og.HEIC",
  "img_15_bad.HEIC",
  "img_16_bad.HEIC",
  "img_17_bad.HEIC",
  "img_18_bad.HEIC",
  "img_19_bad.HEIC",
  "img_1_door.HEIC",
  "img_20_kind_1.HEIC",
  "img_21_kind_1.HEIC",
  "img_22_ausblick.HEIC",
  "img_23_kind_2.HEIC",
  "img_24_kind_2.HEIC",
  "img_25_ausblick.HEIC",
  "img_26_flur_og.HEIC",
  "img_27_ausblick.HEIC",
  "img_28_treppe.HEIC",
  "img_29_eltern.HEIC",
  "img_2_door.HEIC",
  "img_30_eltern.HEIC",
  "img_31_eltern.HEIC",
  "img_32_wc.HEIC",
  "img_33_eltern.HEIC",
  "img_34_eltern.HEIC",
  "img_3_wohn.HEIC",
  "img_4_wohn.HEIC",
  "img_5_eingang.HEIC",
  "img_6_kueche.HEIC",
  "img_7_kueche.HEIC",
  "img_8_kueche.HEIC",
  "img_9_kueche.HEIC"
];

export const flat_6 = [
  "blueprint_1.png",
  "blueprint_2.png",
  "blueprint_3.png",
  "img_10_ausblick.HEIC",
  "img_11_eltern.HEIC",
  "img_12_flur_og.HEIC",
  "img_13_flur_og.HEIC",
  "img_14_bad.jpg",
  "img_15_bad.HEIC",
  "img_16_bad.HEIC",
  "img_17_bad.HEIC",
  "img_18_treppe.HEIC",
  "img_19_treppe.HEIC",
  "img_1_door.HEIC",
  "img_20_treppe.HEIC",
  "img_21_flur_dg.HEIC",
  "img_22_kind.HEIC",
  "img_23_kind.HEIC",
  "img_24_kind.HEIC",
  "img_25_kind.HEIC",
  "img_2_door.HEIC",
  "img_3_wohn.HEIC",
  "img_4_wohn.HEIC",
  "img_5_kueche.HEIC",
  "img_6_treppe.HEIC",
  "img_7_treppe.HEIC",
  "img_8_eltern.HEIC",
  "img_9_ausblick.HEIC"
];

export const flat_7 = [
  "blueprint_1.png",
  "blueprint_2.png",
  "blueprint_3.png",
  "img_1.HEIC",
  "img_10_eltern.HEIC",
  "img_11_eltern.HEIC",
  "img_12_flur_og.HEIC",
  "img_13_bad.HEIC",
  "img_14_ausblick.HEIC",
  "img_15_bad.HEIC",
  "img_16_bad.HEIC",
  "img_17_bad.HEIC",
  "img_18_bad.HEIC",
  "img_19_bad.HEIC",
  "img_20_treppe.HEIC",
  "img_21_kind.HEIC",
  "img_22_kind.HEIC",
  "img_23_kind.HEIC",
  "img_24_kind.HEIC",
  "img_2_wohn.HEIC",
  "img_3_wohn.HEIC",
  "img_4_wohn.HEIC",
  "img_5_kueche.HEIC",
  "img_6_treppe.HEIC",
  "img_7_treppe.HEIC",
  "img_8_flur_og.HEIC",
  "img_9_eltern.HEIC"
];

export const flat_8 = [
  "blueprint_1.png"
];

export const flat_9 = [
  "blueprint_1.png"
];

