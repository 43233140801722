/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-case-declarations */
import { flatData } from '../flat/flats_data'
/* In progress */
export enum Archway_Wrapper {
    HeadInfo = 0,
    Carousel,
    Descrb,
    BottomSthElse,
    CenterTextSep,
    Blueprint,
    Attributes,
}

export function getArchwayInfoWrapped(data: Archway_Wrapper) {
    switch (data) {
        case Archway_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Torbogenzimmer'],
                    titleSlash: true,
                    links: [
                        {
                            text: 'Inmitten des Torbogens sein Zuhause nennen!',
                            to: '/wohnungen/torbogenzimmer',
                            slash: false,
                        },
                    ],

                    forceSubTitlte: true,
                    subTitle: ['Klein aber Fein...'],
                },
                image: {
                    name: 'img_1_wohn',
                    folder: ['imgs', 'archway'],
                    alt: 'Konzeptbild',
                },
                content: {
                    banner: ['32m'],
                    text: [
                        `Willkommen in unserer exklusiven Einzimmerwohnung am Alten Fährhof! ` +
                            `Die einzigartige Lage direkt am Ufer der Saale ermöglicht einen traumhaften Rundumblick auf den Fluss und den idyllischen Innenhof des Alten Fährhofs. ` +
                            `Hier erwartet Sie ein Wohnkonzept, das Komfort und Eleganz vereint. Tauchen Sie ein in eine einzigartige Wohnatmosphäre am Saaleufer.`,
                    ],
                    // link: {
                    //     text: 'Erlebe die Wohnung',
                    //     link: 'bpImage',
                    //     scrollTo: true,
                    // },
                },
            }
        case Archway_Wrapper.Blueprint:
            const _content = new Map<number, string[]>()
            _content.set(0, [])
            _content.set(1, [])
            _content
                .get(0)
                ?.push(
                    `Das Torbogenzimmer präsentiert sich mit einem durchdachten Grundriss auf 30 m² Wohnfläche. ` +
                        `Der großzügige Hauptschlafraum bietet ausreichend Platz für ein bequemes Bett, einen geräumigen Kleiderschrank und eine gemütliche Fernsehecke zum Entspannen. ` +
                        `Zudem gibt es eine praktische Frühstücksecke. Das Badezimmer ist großzügig gestaltet und bietet Raum für eine große bodentiefe Dusche. ` +
                        `Die Küchenzeile ist kompakt und funktional. Das Highlight ist der hohe offene Dachstuhl und malerische Rundumblick.`
                )
            return {
                head: {
                    title: 'Beschreibung des Torbogenzimmers',
                    flat: 14,
                },
                content: _content,
                colorScheme: { r: 0, g: 0, b: 0 },

                levels: ['Obergeschoss'],
                images: [
                    {
                        name: 'blueprint',
                        folder: ['imgs', 'archway'],
                        alt: 'Grundriss Torbogenzimmer ',
                    },
                ],
            }

        case Archway_Wrapper.Attributes:
            const flat = flatData[13]
            const _head = {
                title: 'Details',
                subTitle: 'Wohnung ' + flat.flatNumber + ' - Ausstattung',
            }
            const _flat = [
                String('<strong>' + flat.size + ' m²</strong> Wohnfläche im ' + flat.levels.wLevels[0]),
                flat.rooms.living + ' großer Wohnraum',

                String('Echtholzparkett '),
                String(
                    flat.rooms.baths > 1 ? ' Großes und modernes Badezimmer mit bodentiefer Dusche, und Gäste-WC' : 'Großes und modernes Badezimmer mit bodentiefer Dusche und hochwertigen Armaturen'
                ),
                String('Kompakte Kochnische mit Herd, Mikrowelle, Spüle und Kühlschrank'),
                flat.entrance !== '' && flat.entrance,
                flat.outSideExtra.length > 1 && flat?.outSideExtra,
                String('Niedrige Energiekosten durch hohe Dämmung <strong>(KfW-Effizienzhaus 70)</strong>'),
                ...flat?.technical.heating,
                ...flat?.technical.venting,
                String('Fahrradstellplatz'),
                String('Dazu mietbarer Stellplatz'),
            ]
            return {
                content: {
                    head: _head,
                    flat: _flat,
                    availability: flat.availability,
                    colorScheme: flat.colorScheme,
                    rent: flat.price.main,
                    link: {
                        to: '/kontakt?wohnung=' + flat.flatNumber,
                    },
                },
            }

        case Archway_Wrapper.Descrb:
            return {
                head: {
                    title: ['Hochwertiges Wohnen direkt an der Saale'],
                    titleSlash: false,
                },
                image: {
                    name: 'img_3_bad',
                    folder: ['imgs', 'archway'],
                    alt: 'Badezimmer',
                },
                content: [
                    'Einzigartiges Wohnen im Alten Fährhof!',
                    `Das Torbogenzimmer befindet sich zwischen dem Haupthaus und den Reihenhäusern und bietet einen atemberaubenden Ausblick auf den Innenhof und die Saale. ` +
                        `Das offene Dachgewölbe verleiht der Wohnung eine einzigartige Atmosphäre und eine beeindruckend hohe Decke. ` +
                        `Hier erleben Sie Wohnen mit Charme und Charakter inmitten einer malerischen Umgebung.`,
                ],
            }
        case Archway_Wrapper.Carousel:
            // TODO: NOT AVAILABLE ANYMORE
            return { head: { title: ['Bilder?'] }, images: [] }
            return {
                head: {
                    title: ['Bilder?'],
                },
                images: [
                    {
                        name: 'img_1_wohn',
                        folder: ['imgs', 'archway'],
                        alt: 'Alter Fährhof von Oben',
                    },
                    {
                        name: 'img_2_wohn',
                        folder: ['imgs', 'archway'],
                        alt: 'Alter Fährhof von Oben',
                    },
                    {
                        name: 'img_3_bad',
                        folder: ['imgs', 'archway'],
                        alt: 'Alter Fährhof von Oben',
                    },
                    {
                        name: 'img_4_bad',
                        folder: ['imgs', 'archway'],
                        alt: 'Alter Fährhof von Oben',
                    },
                    {
                        name: 'img_5_bad',
                        folder: ['imgs', 'archway'],
                        alt: 'Alter Fährhof von Oben',
                    },
                    {
                        name: 'img_6_kueche',
                        folder: ['imgs', 'archway'],
                        alt: 'Alter Fährhof von Oben',
                    },
                ],
            }
        case Archway_Wrapper.CenterTextSep:
            return {
                content: ['Der Alte Fährhof besitzt eine starke und weit zurückreichende Geschichte! Erfahren Sie die wie der Alte Fährhof erstellt wurde und steigen Sie in die Geschichte ein.'],
                link: {
                    text: 'Zum Historischen Rückblick',
                    link: '/geschichte',
                    scrollTo: false,
                },
            }
        case Archway_Wrapper.BottomSthElse:
            return {
                title: ['Erkunden Sie auch das weitere Wohnungsangebot'],
                links: [
                    {
                        text: 'Zum Haupthaus ',
                        link: '/wohnungen/haupthaus',
                        scrollTo: false,
                    },
                    {
                        text: 'Zu den Reihenhäusern ',
                        link: '/wohnungen/reihenhaus',
                        scrollTo: false,
                    },
                ],
                image: {
                    name: 'main_6',
                    folder: ['imgs', 'root'],
                    alt: 'Alter Fährhof von Oben',
                },
            }
    }
}
