/* eslint-disable @typescript-eslint/no-unused-expressions */

import { CloudinaryObject, CloudinaryOptions } from './cloudinaryHelper'
import { getMiscInfoWrapped } from './data/misc'
import { getFloorsInfoWrapped } from './data/floors'
import { getHomeInfoWrapped } from './data/home'
import { getTerracedInfoWrapped } from './data/flatTypes/terraced_Wrapped'
import { getMainHouseInfoWrapped } from './data/flatTypes/mainhouse_Wrapped'
import { getArchwayInfoWrapped } from './data/flatTypes/archway_Wrapped'
import { getFlatInfoWrapped } from './data/flat/flat_Wrapped'
import { getHistoryInfoWrapped } from './data/history'
import { getConstructionInfoWrapped } from './data/construction'
import { getLocationInfoWrapped } from './data/location'
import { getContactInfoWrapped } from './data/contact'
import { getOtherProjectsInfoWrapped } from './data/otherProjects'
export interface ServerImageInterface {
    name: string
    folder: string[]
    options: {
        lower?: boolean
        higher?: boolean
        widescreen?: boolean
        alt?: boolean
        uncompressed?: boolean
        navImage?: boolean
    }
    type?: { svg?: boolean; jpeg?: boolean; jpg?: boolean; png?: boolean }
}

export class Image {
    public name: string
    public description?: string
    public folder: string[]
    public alt: string

    constructor(name: string, description: string, folder: string[], alt: string) {
        this.name = name
        this.description = description
        this.folder = folder
        this.alt = alt
    }
}

export class NetworkLayer {
    /** Multipurpose */
    getPageImage(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        image: any,
        res?: {
            lower?: boolean
            higher?: boolean
            widescreen?: boolean
            placeholder?: boolean
            portrait?: boolean
            middle?: boolean
            alt?: boolean
            uncompressed?: boolean
            navImage?: boolean
        },
        type?: { svg?: boolean; jpeg?: boolean; jpg?: boolean; png?: boolean }
    ) {
        if (image === undefined) {
            console.log(image)
        }
        // this.buildCloudinaryOptions(res).then(opt=>{
        const opt = new CloudinaryOptions({ fAuto: true, vcAuto: true })
        res?.widescreen && ((opt.width = 1800), (opt.qCustom = 90))
        res?.higher && ((opt.width = 1300), (opt.qCustom = 90))
        res?.lower && ((opt.width = 650), (opt.qCustom = 95))
        res?.middle && ((opt.width = 900), (opt.qCustom = 90))
        res?.placeholder && ((opt.width = 300), (opt.qCustom = 95))

        res?.portrait && ((opt.cropAspect = 0.9), (opt.width = 1000))

        res?.uncompressed && (opt.width = 4500)
        res?.navImage && ((opt.qCustom = 80), (opt.width = 2000))

        res?.alt && ((opt.qCustom = 80), (opt.width = 400))

        const path: string[] = image.folder

        const obj = new CloudinaryObject(String(image.name), { image: true, dataType: type }, opt, path, image?.project)
        return obj.buildLinkAF()
    }

    /** Data */

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getDataSet(dataset: any, page: string) {
        switch (page) {
            case 'home':
                return getHomeInfoWrapped(dataset)
            case 'flat':
                return getFlatInfoWrapped(dataset)
            case 'floors':
                return getFloorsInfoWrapped(dataset)
            case 'terraced':
                return getTerracedInfoWrapped(dataset)
            case 'mainHouse':
                return getMainHouseInfoWrapped(dataset)
            case 'archway':
                return getArchwayInfoWrapped(dataset)
            case 'history':
                return getHistoryInfoWrapped(dataset)
            case 'construction':
                return getConstructionInfoWrapped(dataset)
            case 'location':
                return getLocationInfoWrapped(dataset)
            case 'otherProjects':
                return getOtherProjectsInfoWrapped(dataset)
            case 'contact':
                return getContactInfoWrapped(dataset)
            case 'misc':
                return getMiscInfoWrapped(dataset)
        }
    }
}
