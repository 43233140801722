import {
    Flat_Wrapper,
    Flat_Wrapper_Levels,
    Flat_Wrapper_Entrance_Side,
    Flat_Wrapper_Outside_Perk,
    Flat_Wrapper_Side,
    Flat_Wrapper_Building,
    Flat_Wrapper_Heating,
    Flat_Wrapper_Garage,
    Flat_Wrapper_Bike,
    Flat_Wrapper_View,
} from './flat_class_Wrapped'
import { checkFlatAvailability } from './flat_gen_Functions'

const technicalDesc_Terraced = {
    heating: [Flat_Wrapper_Heating.Own, Flat_Wrapper_Heating.Floor],
    venting: [],

    garage: [Flat_Wrapper_Garage.Garage, Flat_Wrapper_Garage.Power],
    bike: [Flat_Wrapper_Bike.Bike, Flat_Wrapper_Bike.Lock],
}

const technicalDesc_Main = {
    heating: [Flat_Wrapper_Heating.Central, Flat_Wrapper_Heating.Floor],
    venting: [],

    garage: [Flat_Wrapper_Garage.Garage, Flat_Wrapper_Garage.Power],
    bike: [Flat_Wrapper_Bike.Bike, Flat_Wrapper_Bike.Lock],
}

const rhView2Story = [
    `Im Erdgeschoss erwartet Sie ein großzügiger, offener Wohnbereich mit großen Fenstern und dem Austritt zu Ihrer Terrasse. ` +
        `Ein großer Küchen- und Essbereich laden zum gemeinsamen Kochen ein. ` +
        `Ein praktischer Hauswirtschaftsraum grenzt direkt an die Küche. ` +
        `Der Flur bietet Platz für Schränke und zusätzlichen Stauraum unter der hochwertigen Echtholztreppe. Ein hochwertiges Gäste-WC rundet das Erdgeschoss ab.`,
    `Das Obergeschoss beeindruckt mit Echtholzparkett und bietet ein geräumiges Elternschlafzimmer und Kinderzimmer/Arbeitszimmer. ` +
        `Das Highlight ist das großzügige Badezimmer mit hochwertiger Innenausstattung und bodentiefer Dusche.`,
]

const we_1 = new Flat_Wrapper(
    1,
    { main: 1344, partial: 0 },
    checkFlatAvailability(1),
    112,
    {
        wLevels: [Flat_Wrapper_Levels.Erdgeschoss, Flat_Wrapper_Levels.Dachgeschoss],
        nMain: 2,
    },
    { living: 2, baths: 2, sleep: 2 },
    Flat_Wrapper_Outside_Perk.Terrace,
    Flat_Wrapper_Side.Both,
    Flat_Wrapper_Building.Terraced,
    Flat_Wrapper_Entrance_Side.Courtyard,
    {
        description: [],
    },
    false,
    {
        folder: ['imgs', 'flats', 'flat_1'],
        name: 'img_22_bad',
    },
    technicalDesc_Terraced,
    [Flat_Wrapper_View.Courtyard, Flat_Wrapper_View.Street],
    rhView2Story,
    '',
    { r: 198, g: 155, b: 47 },
    true
)
const we_2 = new Flat_Wrapper(
    2,
    { main: 1236, partial: 0 },
    checkFlatAvailability(2),
    103,
    {
        wLevels: [Flat_Wrapper_Levels.Erdgeschoss, Flat_Wrapper_Levels.Dachgeschoss],
        nMain: 2,
    },
    { living: 3, baths: 2, sleep: 2 },
    Flat_Wrapper_Outside_Perk.Terrace,
    Flat_Wrapper_Side.Both,
    Flat_Wrapper_Building.Terraced,
    Flat_Wrapper_Entrance_Side.Courtyard,
    {
        description: [],
    },
    false,
    {
        folder: ['imgs', 'flats', 'flat_2'],
        name: 'img_14_treppe',
    },
    technicalDesc_Terraced,
    [Flat_Wrapper_View.Courtyard, Flat_Wrapper_View.Street],
    rhView2Story,
    '',
    { r: 155, g: 42, b: 45 },
    true
)
const we_3 = new Flat_Wrapper(
    3,
    { main: 1368, partial: 999 },
    checkFlatAvailability(3),
    114,
    {
        wLevels: [Flat_Wrapper_Levels.Erdgeschoss, Flat_Wrapper_Levels.Dachgeschoss],
        nMain: 2,
    },
    { living: 3, baths: 2, sleep: 2 },
    Flat_Wrapper_Outside_Perk.Terrace,
    Flat_Wrapper_Side.Both,
    Flat_Wrapper_Building.Terraced,
    Flat_Wrapper_Entrance_Side.Courtyard,
    {
        count: 11,
        description: ['Eingangsbereich', 'Flur EG', 'Wohnraum', 'Küche', 'Küchen- & Essbereich', 'Flur OG', 'Flur OG', 'Badezimmer', 'Badezimmer', 'Elternschlafzimmer', 'Kind'],
    },
    true,
    {
        folder: ['imgs', 'root'],
        name: 'terraced_3',
    },
    technicalDesc_Terraced,
    [Flat_Wrapper_View.Courtyard, Flat_Wrapper_View.Street],
    rhView2Story,
    '',
    { r: 29, g: 68, b: 99 }
)
const we_4 = new Flat_Wrapper(
    4,
    { main: 960, partial: 999 },
    checkFlatAvailability(4),
    80,
    {
        wLevels: [Flat_Wrapper_Levels.Erdgeschoss, Flat_Wrapper_Levels.Dachgeschoss],
        nMain: 2,
    },
    { living: 2, baths: 2, sleep: 1 },
    Flat_Wrapper_Outside_Perk.Terrace,
    Flat_Wrapper_Side.Both,
    Flat_Wrapper_Building.Terraced,
    Flat_Wrapper_Entrance_Side.Courtyard,
    {
        description: [],
    },
    true,
    {
        folder: ['imgs', 'root'],
        name: 'terraced_3',
    },
    technicalDesc_Terraced,
    [Flat_Wrapper_View.Courtyard, Flat_Wrapper_View.Street],
    [
        `Im Erdgeschoss erwartet Sie ein großzügiger, offener Wohn- und Kochbereich mit großen Fenstern und dem Austritt zu Ihrer Terrasse. ` +
            `Eine große Kochecke mit Tageslicht und daren direkt angrenzender Hauswirtschaftsraum, laden zum gemeinsamen Kochen ein. ` +
            `Ein hochwertiges Gäste-WC im Eingangsbereich rundet das Erdgeschoss ab.`,
        `Im Obergeschoss erwartet Sie ein großes Elternschlafzimmer mit hochwertigem Echtholzparkett im Flur und Schlafzimmer. ` +
            `Das Highlight bildet das hochwertige Badezimmer mit einer eleganten bodentiefen Dusche.`,
    ],
    '',
    { r: 77, g: 117, b: 86 },
    true
)

const we_5 = new Flat_Wrapper(
    5,
    { main: 1680, partial: 999 },
    checkFlatAvailability(5),
    140,
    {
        wLevels: [Flat_Wrapper_Levels.Erdgeschoss, Flat_Wrapper_Levels.Obergeschoss, Flat_Wrapper_Levels.Dachgeschoss],
        nMain: 3,
    },
    { living: 4, baths: 2, sleep: 3 },
    Flat_Wrapper_Outside_Perk.Terrace,
    Flat_Wrapper_Side.Both,
    Flat_Wrapper_Building.Terraced,
    Flat_Wrapper_Entrance_Side.Courtyard,
    {
        description: [],
    },
    true,
    {
        folder: ['imgs', 'flats', 'flat_5'],
        name: 'img_3_wohn',
    },
    technicalDesc_Terraced,
    [Flat_Wrapper_View.Courtyard, Flat_Wrapper_View.Street],
    [
        `Im Erdgeschoss öffnet sich Ihnen ein einladender Empfangsbereich. Dieser führt Sie zum großzügigen Koch- und Essbereich, der nahtlos in den Wohnbereich übergeht. ` +
            `Die großzügigen bodentiefen Fenster fluten den Raum mit natürlichem Licht und eröffnen einen ungestörten Blick auf Ihre Terrasse. ` +
            `Ein praktischer Abstellraum steht Ihnen für zusätzlichen Stauraum zur Verfügung. ` +
            `Die hochwertige Holztreppe im Eingangsbereich führt Sie elegant in die oberen Stockwerke.`,
        `Das Obergeschoss bietet zwei vielseitig nutzbare Räume, die sich ideal als Kinderzimmer oder Büros eignen. Große Fensterflächen sorgen für ein lichtdurchflutetes Ambiente. ` +
            `Sowohl die Zimmer als auch der Flur sind mit hochwertigem Echtholzparkett ausgestattet. ` +
            `Das hochwertig ausgestattete Badezimmer mit bodentiefer Dusche vervollständigt das Obergeschoss. Über den Flur führt die Treppe Sie weiter hinauf ins Dachgeschoss.`,
        `Im Dachgeschoss erwartet Sie das großzügige Elternschlafzimmer. Die hohen Decken, schönen Ausblicke ` +
            `und das hochwertige Echtholzparkett schaffen einen ruhigen und offenen Raum. ` +
            `Erleben Sie großzügigen Stauraum und einen komfortablen Ankleidebereich. Die Etage wird durch ein zusätzliches Badezimmer perfekt ergänzt.`,
    ],
    '',
    { r: 155, g: 42, b: 45 },
    true
)
const we_6 = new Flat_Wrapper(
    6,
    { main: 1200, partial: 999 },
    checkFlatAvailability(6),
    100,
    {
        wLevels: [Flat_Wrapper_Levels.Erdgeschoss, Flat_Wrapper_Levels.Obergeschoss, Flat_Wrapper_Levels.Dachgeschoss],
        nMain: 3,
    },
    { living: 3, baths: 1, sleep: 2 },
    Flat_Wrapper_Outside_Perk.Terrace,
    Flat_Wrapper_Side.Both,
    Flat_Wrapper_Building.Terraced,
    Flat_Wrapper_Entrance_Side.Courtyard,
    {
        description: [],
    },
    true,
    {
        folder: ['imgs', 'flats', 'flat_6'],
        name: 'img_12_flur_og',
    },
    technicalDesc_Terraced,
    [Flat_Wrapper_View.Courtyard, Flat_Wrapper_View.Street],
    [
        `Im Empfangsbereich des Erdgeschosses finden Sie praktischen Platz für Schuh- und Jackenregale. ` +
            `Von dort aus gelangt man in den großzügigen Wohnraum, welcher über das bodentiefe Fenster einen direkten Zugang zur Terrasse bietet. ` +
            `Der Küchen- und Essbereich ist gut strukturiert und wird durch den angrenzenden Abstellraum ergänzt. ` +
            `Eine hochwertige Treppenanlage befindet sich zentral gelegen und führt Sie in die oberen Stockwerke.`,
        `Im Obergeschoss erwartet Sie ein großzügiger Flur mit ausreichend Platz für Schränke und Stauraum. ` +
            `Das große Elternschlafzimmer besticht durch seine großzügigen Fenster, die einen herrlichen Blick auf die umliegenden Grünanlagen bieten. ` +
            `Sowohl Flur, als auch das Schlafzimmer sind mit hochwertigem Echtholzparkett ausgestattet. ` +
            `Das Highlight bildet das großzügig und hochwertig ausgestattete Badezimmer mit einer bodentiefen Dusche für entspannende Momente.`,
        `Das Dachgeschoss bietet ein geräumiges Kinderzimmer oder Büro, ` +
            `das mit hochwertigem Parkett ausgelegt ist und großzügige Fensterflächen für viel Tageslicht und einen schönen Ausblick bietet.`,
    ],
    '',
    { r: 155, g: 42, b: 45 },
    true
)
const we_7 = new Flat_Wrapper(
    7,
    { main: 1080, partial: 999 },
    checkFlatAvailability(7),
    90,
    {
        wLevels: [Flat_Wrapper_Levels.Erdgeschoss, Flat_Wrapper_Levels.Obergeschoss, Flat_Wrapper_Levels.Dachgeschoss],
        nMain: 3,
    },
    { living: 3, baths: 1, sleep: 2 },
    Flat_Wrapper_Outside_Perk.Terrace,
    Flat_Wrapper_Side.Both,
    Flat_Wrapper_Building.Terraced,
    Flat_Wrapper_Entrance_Side.Courtyard,
    {
        description: [],
    },
    true,
    {
        folder: ['imgs', 'flats', 'flat_7'],
        name: 'img_18_bad',
    },
    technicalDesc_Terraced,
    [Flat_Wrapper_View.Courtyard, Flat_Wrapper_View.Street],
    [
        `Das Erdgeschoss empfängt Sie mit einem einladenden und offenen Eingangsbereich, der nahtlos in den großzügigen Wohnbereich übergeht. ` +
            `Der Küchenbereich ist modern und offen gestaltet, wodurch eine harmonische Verbindung zwischen Kochen und Wohnen entsteht. ` +
            `Eine hochwertige Echtholztreppe führt elegant hinauf. ` +
            `Mit seinem modernen Design und offenem Grundriss bietet das Erdgeschoss einen modern strukturierten Wohnraum für ein harmonisches Zusammenleben.`,
        `Im Obergeschoss erwartet Sie eine ansprechende und großzügige Raumaufteilung. ` +
            `Ein geräumiger Flurbereich führt Sie zu einem hochwertig ausgestatteten Badezimmer mit bodentiefer Dusche. ` +
            `Angrenzend befindet sich das komfortable Elternschlafzimmer. ` +
            `Sowohl im Flur, als auch in dem Schlafzimmer ist Echtholzparkett verlegt.`,
        `Das Dachgeschoss bietet ein großes, seperates Zimmer, das sich perfekt als Kinderzimmer oder Büro eignet. ` +
            `Auch das Dachgeschoss erstrahlt mit der Wärme des Echtholzparkettes und rundet die Wohnung ab.`,
    ],
    '',
    { r: 155, g: 42, b: 45 },
    true
)
const we_8 = new Flat_Wrapper(
    8,
    { main: 840, partial: 999 },
    checkFlatAvailability(8),
    70,
    {
        wLevels: [Flat_Wrapper_Levels.Erdgeschoss],
        nMain: 1,
    },
    { living: 2, baths: 1, sleep: 1 },
    Flat_Wrapper_Outside_Perk.None,
    Flat_Wrapper_Side.Street,
    Flat_Wrapper_Building.Terraced,
    Flat_Wrapper_Entrance_Side.Both,
    {
        description: [],
    },
    false,
    {
        folder: ['imgs', 'root'],
        name: 'main_5',
    },
    technicalDesc_Main,
    [Flat_Wrapper_View.Saale],
    [
        `Die Erdgeschosswohnung 8 im Haupthaus bietet eine umfassende und einladende Wohnatmosphäre am Fuße des imposanten Borlachturms. ` +
            `Das geräumige Schlafzimmer und das exklusive Badezimmer mit bodentiefer Dusche sorgen für Komfort und hohen Wohlfühlfaktor. ` +
            `Der Flur verfügt über einen praktischen Abstellraum. ` +
            `Der Highlight der Wohnung ist der großzügige Wohnraum mit traumhaftem Blick auf die Saale, der von natürlichem Licht durchflutet wird. ` +
            `Die erhöhte Küche verleiht der Wohnung ein exklusives Flair. Genießen Sie die ruhige Atmosphäre und den atemberaubenden Ausblick.`,
    ],
    '',
    { r: 77, g: 117, b: 86 },
    false,
    'Saaleblick'
)
const we_9 = new Flat_Wrapper(
    9,
    { main: 1080, partial: 999 },
    checkFlatAvailability(9),
    90,
    {
        wLevels: [Flat_Wrapper_Levels.Erdgeschoss],
        nMain: 1,
    },
    { living: 2, baths: 1, sleep: 1 },
    Flat_Wrapper_Outside_Perk.Terrace,
    Flat_Wrapper_Side.Courtyard,
    Flat_Wrapper_Building.Main,
    Flat_Wrapper_Entrance_Side.Both,
    {
        description: [],
    },
    false,
    {
        folder: ['imgs', 'root'],
        name: 'main_18',
    },
    technicalDesc_Main,
    [Flat_Wrapper_View.Courtyard],
    [
        `Diese Wohnung besticht durch ihre Ausrichtung zum Innenhof und bietet somit eine ruhige und geschützte Umgebung. ` +
            `Mit einem atemberaubenden Blick auf die Saale besticht das großzügige Schlafzimmer, ` +
            `während das geräumige Badezimmer mit hochwertigen Armaturen und einer bodentiefen Dusche ausgestattet ist.` +
            `Ein besonderes Merkmal dieser Wohnung ist der offene Wohn- und Kochbereich, der durch großzügige, ` +
            `doppelseitige Fenster natürliches Licht erhält und somit eine helle und einladende Atmosphäre schafft. ` +
            `Eine praktische Nische bietet zusätzlichen Stauraum, während die großzügige Terrasse im Innenhof das Wohnkonzept perfekt ergänzt und abrundet.`,
    ],
    '',
    { r: 77, g: 117, b: 86 },
    false,
    'Hofblick'
)

const we_10 = new Flat_Wrapper(
    10,
    { main: 936, partial: 999 },
    checkFlatAvailability(10),
    78,
    {
        wLevels: [Flat_Wrapper_Levels.Obergeschoss],
        nMain: 1,
    },
    { living: 2, baths: 2, sleep: 1 },
    Flat_Wrapper_Outside_Perk.None,
    Flat_Wrapper_Side.Street,
    Flat_Wrapper_Building.Main,
    Flat_Wrapper_Entrance_Side.Both,
    {
        description: [],
    },
    false,
    {
        folder: ['imgs', 'flats', 'flat_10'],
        name: 'img_9_bad',
    },
    technicalDesc_Main,
    [Flat_Wrapper_View.Saale],
    [
        `Die Wohnung im Obergeschoss besticht durch eine atemberaubende Atmosphäre direkt an der Saale. ` +
            `Das großzügige Schlafzimmer und das exklusive Badezimmer mit bodentiefer Dusche bieten hohen Komfort. ` +
            `Ein praktisches zweites Gästebadezimmer ist ebenfalls vorhanden. Der Flur verfügt über einen nützlichen Abstellraum. ` +
            `Das Highlight der Wohnung ist der großzügige Wohnraum mit einem traumhaft erhöhten Blick auf die Saale. ` +
            `Durchflutet von natürlichem Licht entsteht eine einzigartige Atmosphäre. Der Kochbereich integriert sich nahtlos in den Wohn- und Essbereich und rundet diese traumhafte Wohnung ab.`,
    ],
    '',
    { r: 77, g: 117, b: 86 },
    true,
    'Saaleblick'
)
const we_11 = new Flat_Wrapper(
    11,
    { main: 1056, partial: 999 },
    checkFlatAvailability(11),
    88,
    {
        wLevels: [Flat_Wrapper_Levels.Obergeschoss],
        nMain: 1,
    },
    { living: 2, baths: 1, sleep: 1 },
    Flat_Wrapper_Outside_Perk.Balcony,
    Flat_Wrapper_Side.Courtyard,
    Flat_Wrapper_Building.Main,
    Flat_Wrapper_Entrance_Side.Both,
    {
        description: [],
    },
    false,
    {
        folder: ['imgs', 'flats', 'flat_11'],
        name: 'img_4_eltern',
    },
    technicalDesc_Main,
    [Flat_Wrapper_View.Courtyard],
    [
        `Die Wohnung bietet einen einzigartigen Wohnkomfort mit Ausrichtung zum Innenhof. Das großzügige Schlafzimmer beeindruckt mit einem atemberaubenden Blick auf die Saale. ` +
            `Ein angrenzender Abstellraum sorgt für zusätzlichen Stauraum oder kann als Ankleideraum genutzt werden. ` +
            `Das moderne Badezimmer ist mit bodentiefer Dusche und hochwertigen Armaturen ausgestattet. ` +
            `Der offene Wohn- und Kochbereich, durchflutet von großen, doppelseitigen Fenstern, schafft eine helle und einladende Atmosphäre. ` +
            `Ein großzügiger Balkon bietet Ihnen zusätzlich die Möglichkeit, den Innenhof zu betreten und einen privaten Bereich im Freien zu genießen.`,
    ],
    '',
    { r: 77, g: 117, b: 86 },
    false,
    'Hofblick'
)
const we_12 = new Flat_Wrapper(
    12,
    { main: 924, partial: 999 },
    checkFlatAvailability(12),
    77,
    {
        wLevels: [Flat_Wrapper_Levels.Dachgeschoss],
        nMain: 1,
    },
    { living: 2, baths: 1, sleep: 1 },
    Flat_Wrapper_Outside_Perk.None,
    Flat_Wrapper_Side.Street,
    Flat_Wrapper_Building.Main,
    Flat_Wrapper_Entrance_Side.Both,
    {
        description: [],
    },
    false,
    {
        folder: ['imgs', 'flats', 'flat_12'],
        name: 'img_6_bad',
    },
    technicalDesc_Main,
    [Flat_Wrapper_View.Saale],
    [
        `Das große Schlafzimmer und das moderne Badezimmer mit bodentiefer Dusche bieten nicht nur hohen Komfort, ` +
            `sondern auch atemberaubende Ausblicke auf die Saale und die umliegenden Kuranlagen. ` +
            `Der Flur ist mit einem praktischen Abstellraum ausgestattet. ` +
            `Der großzügige Wohnraum bietet einen herrlichen Blick auf die Saale und wird von natürlichem Licht durchflutet. ` +
            `Der Kochbereich, mit Blick auf die angrenzenden Grünanlagen, fügt sich nahtlos in den Wohn- und Essbereich ein und vervollständigt diese traumhafte Wohnung.`,
    ],
    '',
    { r: 77, g: 117, b: 86 },
    true,
    'Saaleblick'
)
const we_13 = new Flat_Wrapper(
    13,
    { main: 936, partial: 999 },
    checkFlatAvailability(13),
    78,
    {
        wLevels: [Flat_Wrapper_Levels.Dachgeschoss],
        nMain: 1,
    },
    { living: 2, baths: 1, sleep: 1 },
    Flat_Wrapper_Outside_Perk._Balcony,
    Flat_Wrapper_Side.Courtyard,
    Flat_Wrapper_Building.Main,
    Flat_Wrapper_Entrance_Side.Both,
    {
        description: [],
    },
    false,
    {
        folder: ['imgs', 'root'],
        name: 'main_7',
    },
    technicalDesc_Main,
    [Flat_Wrapper_View.Courtyard],
    [
        `Diese besondere Wohnung besticht durch ihren einzigartigen Blickpunkt. ` +
            `Darüber hinaus bieten das Schlafzimmer und das hochwertig ausgestattete Badezimmer hohen Komfort und Stil. ` +
            `Der Flur der Wohnung führt zu einem praktischen Abstellraum und öffnet sich zum großzügigen Wohn- und Küchenbereich.` +
            `Ein besonderes Highlight dieser Wohnung ist die private Dachterrasse, die einen einzigartigen Blick auf die Saale bietet. `,
    ],
    '',
    { r: 77, g: 117, b: 86 },
    false,
    'Saaleblick'
)

const we_14 = new Flat_Wrapper(
    14,
    { main: 500, partial: 999 },
    checkFlatAvailability(14),
    30,
    {
        wLevels: [Flat_Wrapper_Levels.Obergeschoss],
        nMain: 1,
    },
    { living: 1, baths: 1, sleep: 1 },
    Flat_Wrapper_Outside_Perk.None,
    Flat_Wrapper_Side.Both,
    Flat_Wrapper_Building.Main,
    Flat_Wrapper_Entrance_Side.Both,
    {
        description: [],
    },
    false,
    {
        folder: ['imgs', 'root'],
        name: 'main_2',
    },
    technicalDesc_Main,
    [Flat_Wrapper_View.Courtyard, Flat_Wrapper_View.Saale],
    ['Tolle Sicht'],
    '',
    { r: 130, g: 83, b: 70 },
    true
    // { r: 77, g: 117, b: 86 }
)

export const flatData = [we_1, we_2, we_3, we_4, we_5, we_6, we_7, we_8, we_9, we_10, we_11, we_12, we_13, we_14]
