import { StyleSheet } from 'react-native-web'

export const footerStyles = StyleSheet.create({
    logo: {
        parent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: 0,
            paddingBottom: '0.7em',
        },
        image: {
            height: '80pt',
            marginBottom: 10,
            marginTop: 10,
            // aspectRatio: 3.6646,
            aspectRatio: 1.1023809524,
            display: 'flex',
            justifyContent: 'center',
        },
        image_: {
            height: '80pt',
            marginBottom: 10,
            marginTop: 10,
            // aspectRatio: 3.6646,
            aspectRatio: 0.937,
            display: 'flex',
            justifyContent: 'center',
        },
    },
    list: {
        parent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: 0,
            paddingBottom: '0.5em',
        },
        unstyled: {
            color: 'white',
            textDecoration: 'none',
        },
        title: {
            fontWeight: 'bold',
        },
        mailto: {
            textDecoration: 'underline',
            WebkitFontSmoothing: 'antialiased',
            lineHeight: '1.5',
            letterSpacing: '.03em',
            display: 'inline-block',
            textUnderlinePosition: 'under',
            fontSize: 'larger',
            fontWeight: '600',
            paddingLeft: '10px',
            paddingRight: '10px',
            borderRadius: '7px',
        },
        telephone: {
            marginTop: '1em',
            textDecoration: 'none',
            fontSize: 'larger',
            wordSpacing: '5pt',
            fontWeight: '600',
            paddingLeft: '10px',
            paddingRight: '10px',
            borderRadius: '7px',
        },
        links: {
            marginTop: '1em',
        },
        linkItem: {
            color: 'white',
            fontColor: 'white',
            fontSize: '12pt',
        },
    },
    settings: {
        parent: {
            marginTop: '1em',
        },
        parent_: {
            marginTop: '1em',
            textAlign: 'left',
            marginLeft: '1em',
            fontSize: '14px',
            letterSpacing: '.03em',
        },
        cookieSettings: {
            textDecoration: 'underline',
            display: 'inline-block',
            textUnderlinePosition: 'under',
            fontSize: '14px',
            letterSpacing: '0.03em',
            color: 'rgba(255,255,255,.6)',
        },
        impressum: {
            textDecoration: 'none',
            fontSize: '14px',
            letterSpacing: '0.03em',
        },
        impressum_: {
            textDecoration: 'underline',
            display: 'inline-block',
            textUnderlinePosition: 'under',
            fontSize: '14px',
            color: 'rgba(255,255,255,.6)',
            letterSpacing: '0.03em',
            paddingLeft: '1em',
        },
    },
})
