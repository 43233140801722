/* eslint-disable no-var */
/* In progress */
export enum History_Wrapper {
    HeadInfo = 0,
    Descrb,
    Carousel,
    CenterTextSep,
    BottomSthElse,
}

export class HistoryPage_Content {
    constructor(public year: string, public headline: string, public content?: HistoryPage_SubContent[]) {}
}

export class HistoryPage_SubContent {
    constructor(public content: string[], public year?: string) {}
}

export function getHistoryInfoWrapped(data: History_Wrapper) {
    switch (data) {
        case History_Wrapper.HeadInfo:
            return {
                head: {
                    title: ['Geschichte'],
                    titleSlash: true,
                    links: [
                        {
                            text: 'Ein Blick in die Vergangenheit',
                            to: '/wohnungen/reihenhaus',
                            slash: false,
                        },
                    ],
                    subTitle: ['Erfahren Sie mehr über die prägenden Jahrhunderte des Alten Fährhofs. Von seiner Entstehung bis zur heutigen Wohnimmobilie...'],
                },
                image: {
                    name: 'img_history_8',
                    folder: ['imgs', 'history'],
                    alt: 'Soolbad Dürrenberg a. Saale. - Salinen-Gasthof',
                },
                content: {
                    banner: ['ab 76m²', 'bis 109m²'],
                    text: [
                        `Der "Alte Fährhof" ist ein Ort voller Geschichte und Bedeutung. `+
                        `Im Laufe der Jahrhunderte hat der Fährhof viele Veränderungen erlebt und birgt Erinnerungen an bedeutsame Ereignisse der Vergangenheit. `+
                        `Wir laden Sie ein, die Geschichte des "Alten Fährhofs" auf unserer Webseite zu entdecken und mehr über seine Bedeutung und seine Vergangenheit zu erfahren.`,
                    ],
                },
            }
        case History_Wrapper.CenterTextSep:
            return {
                content: [
                    `Möchten Sie gerne weitere Informationen über den Wohnkomplex erhalten oder eine Besichtigung vereinbaren? `+
                    `Bitte kontaktieren Sie uns und wir werden uns schnellstmöglich um Ihre Anfrage kümmern.`,
                ],
                link: {
                    text: 'Kontaktieren Sie uns',
                    link: '/kontakt',
                    scrollTo: false,
                },
            }
        case History_Wrapper.Carousel:
            return {
                head: {
                    title: ['Bilder?'],
                },
                images: [
                    {
                        name: 'img_history_1',
                        folder: ['imgs', 'history'],
                        alt: 'Postkartenansicht',
                    },
                    {
                        name: 'img_history_2',
                        folder: ['imgs', 'history'],
                        alt: 'Bad Dürrenberg Ansicht',
                    },
                    {
                        name: 'img_history_3',
                        folder: ['imgs', 'history'],
                        alt: 'Postkartenansicht - Nahansicht',
                    },
                    {
                        name: 'img_history_4',
                        folder: ['imgs', 'history'],
                        alt: 'Postkartenansicht Saale',
                    },
                    {
                        name: 'img_history_5',
                        folder: ['imgs', 'history'],
                        alt: 'Postkartenansicht Sooleproduktion',
                    },
                    {
                        name: 'img_history_6',
                        folder: ['imgs', 'history'],
                        alt: 'Saale Ansicht',
                    },
                    {
                        name: 'img_history_7',
                        folder: ['imgs', 'history'],
                        alt: 'Ansicht Sooleförderung',
                    },
                    {
                        name: 'img_history_8',
                        folder: ['imgs', 'history'],
                        alt: 'Salinen-Gasthof',
                    },
                    {
                        name: 'img_history_9',
                        folder: ['imgs', 'history'],
                        alt: 'Saale Saline-Kraftwerk',
                    },
                    {
                        name: 'img_history_10',
                        folder: ['imgs', 'history'],
                        alt: 'Ansicht Borlachturm und Salzamt',
                    },
                    {
                        name: 'img_history_11',
                        folder: ['imgs', 'history'],
                        alt: 'Postkartensicht Königliches Soolebad',
                    },
                ],
            }
        case History_Wrapper.BottomSthElse:
            return {
                title: ['Mehr zum Alten Fährhof'],
                links: [
                    {
                        text: 'Bauverlauf',
                        link: '/bauverlauf',
                        scrollTo: false,
                    },
                    {
                        text: 'Mietwohnungen',
                        link: '/wohnungen',
                        scrollTo: false,
                    },
                ],
                image: {
                    name: 'main_19',
                    folder: ['imgs', 'root'],
                    alt: 'Alter Fährhof von Oben',
                },
            }
        case History_Wrapper.Descrb:
            var _content: HistoryPage_Content[] = []

            /// First Time Content
            var _1_Entry_SubContent: HistoryPage_SubContent[] = []
            _1_Entry_SubContent.push(
                new HistoryPage_SubContent(['Das deutsche Ritterheer unter Führung König Heinrich I besiegt in der „Ungarnschlacht“ die eingefallenen Ungarn.'], '1. März, 933'),
                new HistoryPage_SubContent(
                    [
                        `Erste urkundliche Erwähnung des Ortsteiles „Keuschberg“. `+
                        `Otto I schenkt dem Kaplan Günther zwölf königliche Hufen im Dorf Uglici (Ögelitsch) im Burgwart Cuskiburg (Keuschberg).`,
                    ],
                    '1. Juli, 933'
                )
            )

            var _1_Entry = new HistoryPage_Content('6500 v. Chr.', 'Besiedlung im Bereich der Saaleaue.', _1_Entry_SubContent)
            _content.push(_1_Entry)

            var _2_Entry = new HistoryPage_Content('1012', 'Erste Erwähnung der Kirche Keuschberg.')
            _content.push(_2_Entry)

            var _3_Entry = new HistoryPage_Content('1320', 'Erste Erwähnung von Kirchdorf.')
            _content.push(_3_Entry)

            var _4_Entry = new HistoryPage_Content(
                '1348',
                `Urkundliche Erwähnung des Rittergutes derer von Wolffersdorff „Uffm dürren Berge“ `+
                `dem heutigen „Alten Fährhof“ und „Alte Salzamt“ an der Saale. Bis 1671 in Familienbesitz derer von Wolffersdorff.`
            )
            _content.push(_4_Entry)

            var _5_Entry = new HistoryPage_Content('1632', 'Nach der Schlacht bei Lützen wird das Rittergut geplündert und gebrandschatzt.')
            _content.push(_5_Entry)

            var _6_Entry = new HistoryPage_Content(
                '1. Juni 1741',
                'Der kurfürstlich-sächsische Bergrat Johann-Gottfried Borlach (1687-1768) unternimmt Bohrversuche auf der Suche nach Salz am Rittergut bei Keuschberg direkt an der Saale.'
            )
            _content.push(_6_Entry)

            var _7_Entry = new HistoryPage_Content('1757', 'Unterbrechung der Bohrungen und Schachtarbeiten durch den Siebenjährigen Krieg.')
            _content.push(_7_Entry)

            var _8_Entry = new HistoryPage_Content('15. September 1763', 'Erfolgreiche Bohrung und Schachtarbeiten mit bis heute ständigem Solefluss (10,63 Prozent Salzgehalt).')
            _content.push(_8_Entry)

            var _9_Entry = new HistoryPage_Content('1. März 1764', 'Aufnahme der Salztransporte mit Gespanne an dem bestehenden Fährübergang der Saale.')
            _content.push(_9_Entry)

            /// Tenth Content
            var _10_Entry_SubContent: HistoryPage_SubContent[] = []
            _10_Entry_SubContent.push(
                new HistoryPage_SubContent(['Errichtung des „Witzlebenturm“ mit Einbau der Brendelschen Dampfmaschine als erste Dampfmaschine Sachsens. ']),
                new HistoryPage_SubContent([
                    'Umbau des stattlichen Gutshofes zum „Salinen Gasthof“ mit Gastraum und Unterkünften für die Fuhrleute. Vorplatz zur Saale als Umschlagplatz für den Salztransport.',
                ])
            )
            var _10_Entry = new HistoryPage_Content('1811 - 1816', '', _10_Entry_SubContent)
            _content.push(_10_Entry)

            var _11_Entry = new HistoryPage_Content('1914 / 1915', 'Bau der ersten Saalebrücke als Straßenbrücke und damit verbunden das Ende des Fährbetriebes über der Saale.')
            _content.push(_11_Entry)

            var _12_Entry = new HistoryPage_Content('1923 / 1924', 'Umbau der Pferdeställe und Nebengebäude der Ausspanne zu Wohnungen.')
            _content.push(_12_Entry)

            var _13_Entry = new HistoryPage_Content('1934', 'Skelettfunde aus der Mittelsteinzeit unmittelbar neben dem alten Rittergut im Kurpark (Dürrenberger Schamanin).')
            _content.push(_13_Entry)

            var _14_Entry = new HistoryPage_Content('1963 / 1964', 'Ende der Produktion von Siedesalz und des Badebetriebes.')
            _content.push(_14_Entry)

            var _15_Entry = new HistoryPage_Content('24.Juni 2000', 'Inbetriebnahme des neuen Solebrunnens und des Kurbetriebs.')
            _content.push(_15_Entry)

            var _16_Entry = new HistoryPage_Content(
                '2021 - 2023',
                'Nach längerem Leerstand und Verfall umfassende Sanierung des „Alten Fährhofes“ mit dem denkmalgeschützten „Salinen Gasthof“ und dem Denkmalbereich „Ausspanne“ zu 14 Wohnungen.'
            )
            _content.push(_16_Entry)

            return {
                head: {
                    title: 'Historische Ereignisse',
                },
                content: _content,
                images: [
                    {
                        name: 'img_history_7',
                        folder: ['imgs', 'history'],
                        alt: 'Postkartenansicht Sooleproduktion',
                    },
                    {
                        name: 'img_history_6',
                        folder: ['imgs', 'history'],
                        alt: 'Postkartenansicht Sooleproduktion',
                    },
                    {
                        name: 'img_history_11',
                        folder: ['imgs', 'history'],
                        alt: 'Postkartensicht Königliches Soolebad',
                    },
                    {
                        name: 'main_1',
                        folder: ['imgs', 'root'],
                        alt: 'Postkartenansicht Sooleproduktion',
                    },
                ],
            }
    }
}
